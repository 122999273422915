import { executeTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { useCachedDataLoading } from 'common-front/src/hooks/useCachedDataLoading';
import {
    EventId,
    OrganizationId,
    PositionsCategoriesQuery,
    TagsQuery
} from 'common/src/generated/types';
import * as React from 'react';
import { executePositionsCategoriesQuery } from '../../generated/graphqlHooks';

interface IPoint {
    latitude: number;
    longitude: number;
}

interface IPositionsContext {
    newPositionLatLng?: IPoint;

    getPositionsCategories(): Promise<PositionsCategoriesQuery['event']['positionsCategories']>;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
    setNewPositionLatLng(point: IPoint): void;
}

const PositionsContext = React.createContext<IPositionsContext>({} as IPositionsContext);

interface IPositionsContextProviderProps {
    children: React.ReactNode;
    eventId: EventId;
    organizationId: OrganizationId;
}

export const PositionsContextProvider = (props: IPositionsContextProviderProps) => {
    const [point, setPoint] = React.useState<IPoint | undefined>();
    const getPositionsCategories = useCachedDataLoading(
        executePositionsCategoriesQuery,
        { eventId: props.eventId },
        (d) => d.event.positionsCategories
    );
    const getTags = useCachedDataLoading(
        executeTagsQuery,
        { organizationId: props.organizationId },
        (d) => d.organization.tags.nodes
    );

    return (
        <PositionsContext.Provider
            value={{
                newPositionLatLng: point,
                getPositionsCategories,
                getTags,
                setNewPositionLatLng: setPoint
            }}
        >
            {props.children}
        </PositionsContext.Provider>
    );
};

export function usePositionsContext() {
    return React.useContext(PositionsContext);
}
