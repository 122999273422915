import {
    DocumentInput,
    FormsCustomFieldInput,
    OrganizationCustomFieldsFrontQuery
} from 'common/src/generated/types';
import { FormApi } from 'final-form';
import * as React from 'react';
import { useCSVHeader } from '../../volunteers/import/useCSVHeader';
import { MappingsFormBox } from './mappingsFormBox';
import { PresetsFormBox } from './presetsFormBox';

type ImportInputWithCustomFields = {
    csv: DocumentInput;
    mappings: (string | null)[];
    presets: FormsCustomFieldInput[];
};

interface IMappingsFormBoxes<T> {
    customFields: OrganizationCustomFieldsFrontQuery['organization']['customFields']['nodes'];
    defaultColumns: Record<string, string>;
    defaultFieldsOverride?: React.ReactNode;
    file: File;
    form: FormApi<{ input: T }>;
    modeSelection?: React.ReactNode;
    requiredColumns: string[];
    values: { input: T };
}

export function MappingsFormBoxes<T extends ImportInputWithCustomFields>({
    customFields,
    defaultColumns,
    defaultFieldsOverride,
    file,
    form,
    modeSelection,
    requiredColumns,
    values
}: IMappingsFormBoxes<T>) {
    const { isLoading, columns, errors } = useCSVHeader(file);

    if (isLoading || errors) {
        return null; /* Loading doesn’t take long: don’t display a loader. Errors are aldready displayed in the <FileS3Input />. */
    } else {
        return (
            <>
                <MappingsFormBox
                    columns={columns}
                    customFields={customFields}
                    defaultColumns={defaultColumns}
                    defaultFieldsOverride={defaultFieldsOverride}
                    modeSelection={modeSelection}
                    requiredColumns={requiredColumns}
                />

                <PresetsFormBox
                    change={form.change}
                    customFields={customFields}
                    values={values.input.presets}
                />
            </>
        );
    }
}
