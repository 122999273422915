import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { CustomFieldsQuery, OrganizationId, SegmentId } from 'common/src/generated/types';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { CustomFieldCells } from './customFieldCells';
import { CustomFieldRowDropdown } from './customFieldRowDropdown';

interface ICustomFieldRowProps {
    columns: PossibleColumn[];
    customField: CustomFieldsQuery['organization']['rows']['nodes'][0];
    organizationId: OrganizationId;
    segmentId: SegmentId;

    reload(): void;
}

export const CustomFieldRow = (props: ICustomFieldRowProps) => {
    const translate = useTranslate();
    const history = useHistory();

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onMouseLeave={(e) => {
                    closeOtherDropdowns(e.target);
                }}
            >
                {props.columns.map((field) => (
                    <CustomFieldCells
                        key={field.slug}
                        column={field}
                        organizationId={props.organizationId}
                        row={props.customField}
                        segmentId={props.segmentId}
                    />
                ))}

                <CellControls justify="end">
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <I
                                    icon="pen"
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.EDIT_CUSTOM_FIELD(
                                                props.organizationId,
                                                props.segmentId,
                                                props.customField.id
                                            )
                                        );
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('_diter_62574')}</Content>
                    </Tooltip>

                    <CustomFieldRowDropdown
                        customField={props.customField}
                        organizationId={props.organizationId}
                        reload={props.reload}
                        segmentId={props.segmentId}
                    />
                </CellControls>
            </Row>
        </>
    );
};
