import {
    ALL_LANGUAGE,
    ALL_SEX,
    CustomFieldWithConditionFragment,
    FieldProperty,
    FieldType
} from 'common/src/generated/types';
import { CountriesService } from 'common/src/services/countriesService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import * as React from 'react';
import { useEnumToOptions } from './useEnumToOptions';

export function useCustomFieldOptions(
    customField: Emptyable<
        Pick<CustomFieldWithConditionFragment, 'fieldProperty' | 'fieldType' | 'values'>
    >
) {
    const countriesService = useService(CountriesService);
    const enumToOptions = useEnumToOptions();

    return React.useMemo(() => {
        if (customField) {
            if (customField.fieldType === FieldType.Country) {
                return countriesService.getCountriesIdName().map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ));
            } else if (customField.fieldType === FieldType.Language) {
                if (customField.fieldProperty === FieldProperty.Language) {
                    return enumToOptions(USABLE_LANGUAGES);
                } else {
                    return enumToOptions(ALL_LANGUAGE);
                }
            } else if (customField.fieldType === FieldType.Nationality) {
                return countriesService.getNationalitiesIdName().map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ));
            } else if (customField.fieldType === FieldType.Sex) {
                return enumToOptions(ALL_SEX);
            } else {
                return customField.values.map(({ id, value }) => (
                    <option key={id} value={id}>
                        {value}
                    </option>
                ));
            }
        } else {
            return <></>;
        }
    }, [customField]);
}
