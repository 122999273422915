import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Fields, FieldService } from 'common/src/vo/field';
import { DateTime } from 'luxon';
import * as React from 'react';
import { DateInput } from '../../designSystem/components/date/dateInput';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdateDateCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;

    mutate(value: string, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateDateCell = (props: IUpdateDateCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.fields);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<DateTime | string>(props.customField, props.fields);

        if (isNonEmptyString(value)) {
            return DateTime.fromISO(value, { zone: 'utc' });
        } else {
            return value;
        }
    }, []);
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={initialValue as any}
            mutate={mutate}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <DateInput label={props.customField.name} value={value} onChange={setValue} />
            )}
            renderValue={valueString}
        />
    );
};
