import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { TextInput } from '../../designSystem/components/textInput';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdateTextCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;

    mutate(value: string, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateTextCell = (props: IUpdateTextCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.fields);
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={valueString}
            mutate={mutate}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput label={props.customField.name} value={value} onChange={setValue} />
            )}
            renderValue={valueString}
        />
    );
};
