import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Accreditation, CustomFieldWithConditionFragment } from 'common/src/generated/types';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';

interface IAccreditationsSlotParametersCardProps {
    accreditationCategoryName: string;
    accreditationName?: string;
    color: Accreditation['color'];
    customFields: CustomFieldWithConditionFragment[];
    fields: Fields;
    icon: Accreditation['icon'];
}

export const AccreditationsSlotParametersCard = ({
    accreditationCategoryName,
    accreditationName,
    color,
    customFields,
    fields,
    icon
}: IAccreditationsSlotParametersCardProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);

    return (
        <DetailBox
            css={{
                flex: '2 1'
            }}
            title={translate('param_tres_78420')}
        >
            <Flex direction="column" gap="4" width={1}>
                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('cat_gorie_00291')}</Box>

                    <Box color="gray800">{accreditationCategoryName}</Box>
                </Flex>

                {accreditationName && (
                    <>
                        <Separator direction="horizontal" />

                        <Flex gap="3" justify="between">
                            <Box color="gray500">{translate('Accreditation')}</Box>

                            <Box color="gray800">{accreditationName}</Box>
                        </Flex>
                    </>
                )}

                <Separator direction="horizontal" />

                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('Color')}</Box>

                    <Box
                        color="gray800"
                        css={{
                            background: color,
                            borderRadius: '20px',
                            height: '20px',
                            width: '20px'
                        }}
                    />
                </Flex>

                <Separator direction="horizontal" />

                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('ic_ne_55554')}</Box>

                    <Box color="gray800">
                        <I icon={fromIconVO(icon)} />
                    </Box>
                </Flex>

                {customFields.map((cf) => {
                    if (fields[cf.slug]) {
                        return (
                            <React.Fragment key={cf.slug}>
                                <Separator direction="horizontal" />

                                <Flex gap="3" justify="between">
                                    <Box color="gray500">{cf.name}</Box>

                                    <Box color="gray800">
                                        {fieldService.getValueString(cf, fields)}
                                    </Box>
                                </Flex>
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                })}
            </Flex>
        </DetailBox>
    );
};
