import { UsersInfosMassEditModal } from 'common-front/src/components/massEdit/massEditModal';
import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger as TooltipTrigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import {
    useUsersInfosMassEditMutation,
    useVolunteersRegistrationsMassDeleteMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUseMassActionsResult } from 'common-front/src/hooks/useMassActions';
import { Box } from 'common/src/designSystem/components/box';
import { Feature, UsersInfoId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Predicates } from 'common/src/vo/segment';
import * as React from 'react';
import { useVolunteerActionsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { MassAccreditModal } from '../massAccredit/massAccreditModal';
import { MassAccreditationStateModal } from '../massAccreditationStateUpdate/massAccreditationStateModal';
import { MassAssignModal } from '../massAssign/massAssignModal';
import { MassCampaignSendModal } from '../massCampaignSend/massCampaignSendModal';
import { MassDelegationAddModal } from '../massDelegationAdd/massDelegationAddModal';
import { MassFormAddModal } from '../massFormAdd/massFormAddModal';
import { MassFormRemoveModal } from '../massFormRemove/massFormRemoveModal';
import { MassPreAssignModal } from '../massPreAssign/massPreAssignModal';
import { MassStateUpdateModal } from '../massStateUpdate/massStateUpdateModal';

interface IVolunteersActionsProps {
    massActions: IUseMassActionsResult<UsersInfoId>;
    predicates: Emptyable<Predicates>;
    showOnlyMassEdit: boolean;
    totalNumberOfMembers: number;

    onMassEditSuccess(): void;
}

export const VolunteersActions = (props: IVolunteersActionsProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { organization, hasFeature } = useOrganizationContext();
    const { mutate: usersInfosMassEdit } = useUsersInfosMassEditMutation();
    const { mutate: volunteersRegistrationsMassDelete } =
        useVolunteersRegistrationsMassDeleteMutation();
    const { data } = useVolunteerActionsQuery({ eventId: eventId! });
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassStateUpdatevisible, setIsMassStateUpdatevisible] = React.useState(false);
    const [isMassAffectVisible, setIsMassAffectVisible] = React.useState(false);
    const [isMassAccreditVisible, setIsMassAccreditVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isMassPreAffectVisible, setIsMassPreAffectVisible] = React.useState(false);
    const [isMassAccreditationStateVisible, setIsMassAccreditationStateVisible] =
        React.useState(false);
    const [isMassDelegationAddVisible, setIsMassDelegationAddVisible] = React.useState(false);
    const [isMassFormAddVisible, setIsMassFormAddVisible] = React.useState(false);
    const [isMassFormRemoveVisible, setIsMassFormRemoveVisible] = React.useState(false);
    const [isMassCampaignSendVisible, setIsMassCampaignSendVisible] = React.useState(false);
    const selectedsInput = props.massActions.getSelectedsInput(props.predicates, null);
    const finalNumberOfSelected = props.massActions.isAllSelected
        ? props.totalNumberOfMembers
        : props.massActions.numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_un_champ_64621')}
            </Button>

            {!props.showOnlyMassEdit && (
                <Tooltip>
                    <TooltipTrigger>
                        <Box>
                            <Button
                                color="white"
                                leftIcon="wand-magic-sparkles"
                                onClick={() => {
                                    setIsMassAffectVisible(true);
                                }}
                            />
                        </Box>
                    </TooltipTrigger>

                    <Content placement="top">
                        {translate('affecter_1_me_24704', finalNumberOfSelected)}
                    </Content>
                </Tooltip>
            )}

            {!props.showOnlyMassEdit && hasFeature(Feature.Accreditation) && (
                <Tooltip>
                    <TooltipTrigger>
                        <Box>
                            <Button
                                color="white"
                                leftIcon="badge-check"
                                onClick={() => {
                                    setIsMassAccreditVisible(true);
                                }}
                            />
                        </Box>
                    </TooltipTrigger>

                    <Content placement="top">
                        {translate('accr_diter_1_13773', finalNumberOfSelected)}
                    </Content>
                </Tooltip>
            )}

            {!props.showOnlyMassEdit && (
                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" />
                    </Trigger>

                    <Menu maxHeight={400} placement="bottom-end">
                        <Title>{translate('affectation_plural')}</Title>

                        <ItemIcon
                            icon="wand-magic-sparkles"
                            onClick={() => {
                                setIsMassStateUpdatevisible(true);
                            }}
                        >
                            {translate('changer_le_stat_39694')}
                        </ItemIcon>

                        <ItemIcon
                            icon="wand-magic-sparkles"
                            onClick={() => {
                                setIsMassPreAffectVisible(true);
                            }}
                        >
                            {translate('pr_affecter_1_62649', finalNumberOfSelected)}
                        </ItemIcon>

                        <Separator color="gray100" direction="horizontal" />

                        <Title>{translate('accr_ditations_39450')}</Title>

                        {hasFeature(Feature.Accreditation) && (
                            <ItemIcon
                                icon="badge-check"
                                onClick={() => {
                                    setIsMassAccreditationStateVisible(true);
                                }}
                            >
                                {translate('changer_le_stat_27596')}
                            </ItemIcon>
                        )}

                        {hasFeature(Feature.Delegation) && (
                            <ItemIcon
                                icon="people-group"
                                isDisabled={!data.event?.hasDelegations}
                                onClick={() => {
                                    setIsMassDelegationAddVisible(true);
                                }}
                            >
                                {translate('ajouter_1_mem_86218', finalNumberOfSelected)}
                            </ItemIcon>
                        )}

                        <Separator color="gray100" direction="horizontal" />

                        <Title>{translate('communication_05277')}</Title>

                        <ItemIcon
                            icon="file-lines"
                            isDisabled={!data.event?.hasForms}
                            onClick={() => {
                                setIsMassFormAddVisible(true);
                            }}
                        >
                            {translate('ajouter_1_mem_52947', finalNumberOfSelected)}
                        </ItemIcon>

                        <ItemIcon
                            icon="file-lines"
                            isDisabled={!data.event?.hasForms}
                            onClick={() => {
                                setIsMassFormRemoveVisible(true);
                            }}
                        >
                            {translate('marquer_un_form_10633')}
                        </ItemIcon>

                        <ItemIcon
                            icon="paper-plane"
                            onClick={() => {
                                setIsMassCampaignSendVisible(true);
                            }}
                        >
                            {translate('envoyer_une_cam_94630')}
                        </ItemIcon>

                        <Separator color="gray100" direction="horizontal" />

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteModalOpen(true);
                            }}
                        >
                            {translate('d_sinscrire_de_06416')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            )}

            {isMassEditVisible && (
                <UsersInfosMassEditModal
                    mutate={(massEdit) =>
                        usersInfosMassEdit({
                            organizationId: organization.id,
                            eventId: eventId!,
                            massEdit
                        })
                    }
                    organizationId={organization.id}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassStateUpdatevisible && (
                <MassStateUpdateModal
                    eventId={eventId!}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassStateUpdatevisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassAffectVisible && (
                <MassAssignModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassAffectVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassAccreditVisible && (
                <MassAccreditModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassAccreditVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassPreAffectVisible && (
                <MassPreAssignModal
                    eventId={eventId!}
                    numberOfSelected={finalNumberOfSelected}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassPreAffectVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('d_sinscrire_27046')}
                    subtext={[translate('_tes_vous_s_r_d_46029')]}
                    text={translate('d_sinscription_66971', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await volunteersRegistrationsMassDelete({
                            eventId: eventId!,
                            selecteds: selectedsInput
                        });

                        props.onMassEditSuccess();
                    }}
                />
            )}

            {isMassAccreditationStateVisible && (
                <MassAccreditationStateModal
                    eventId={eventId!}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassAccreditationStateVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassFormAddVisible && (
                <MassFormAddModal
                    eventId={eventId!}
                    organizationId={organization.id}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassFormAddVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassFormRemoveVisible && (
                <MassFormRemoveModal
                    eventId={eventId!}
                    organizationId={organization.id}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassFormRemoveVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassDelegationAddVisible && (
                <MassDelegationAddModal
                    eventId={eventId!}
                    organizationId={organization.id}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassDelegationAddVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}

            {isMassCampaignSendVisible && (
                <MassCampaignSendModal
                    eventId={eventId}
                    organizationId={organization.id}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassCampaignSendVisible(false);
                    }}
                    onSuccess={props.onMassEditSuccess}
                />
            )}
        </>
    );
};
