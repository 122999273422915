import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { VolunteersRendering } from 'common-front/src/vo/volunteersRendering';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    SegmentCustomFieldFragment,
    UsersInfoId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { VolunteersCalendar } from './calendar/volunteersCalendar';
import { VolunteersList } from './list/volunteersList';
import { VolunteersActions } from './volunteersActions';
import { VolunteersHeader } from './volunteersHeader';

interface IVolunteersContentProps {
    customFields: SegmentCustomFieldFragment[];
}

export const VolunteersContent = ({ customFields }: IVolunteersContentProps) => {
    const translate = useTranslate();
    const { isEventAdmin } = useEventContext();
    const {
        limit,
        massActions,
        onMassEditSuccess,
        predicates,
        reloadKey,
        rendering,
        segment,
        setReloadKey
    } = useSegmentsContext<UsersInfoId, VolunteersRegistrationsSortAttributes>();
    const showEditColumns = isEventAdmin && rendering === VolunteersRendering.List;
    const showEditMode = isEventAdmin && rendering === VolunteersRendering.List;

    return (
        <>
            <VolunteersHeader
                customFields={customFields}
                reload={() => {
                    setReloadKey(reloadKey + 1);
                }}
            />

            <Spacer height="7" />

            <SegmentTableHeader
                searchRow={
                    <SearchRow
                        actions={
                            <VolunteersActions
                                massActions={massActions}
                                predicates={predicates}
                                showOnlyMassEdit={false}
                                totalNumberOfMembers={segment.count}
                                onMassEditSuccess={onMassEditSuccess}
                            />
                        }
                        searchPlaceholder={translate('rechercher_un_m_05904')}
                        showEditColumns={showEditColumns}
                        showEditMode={showEditMode}
                        showSearchColumns={true}
                    />
                }
                selectSegment={
                    <SelectSegment
                        pageSelectedText={translate('les_1_membres_00895', limit)}
                        segmentSelectedText={translate(
                            'les_1_membres_56748',
                            segment.count,
                            segment.name
                        )}
                    />
                }
            />

            {rendering === VolunteersRendering.Calendar ? (
                <VolunteersCalendar />
            ) : (
                <VolunteersList customFields={customFields} />
            )}
        </>
    );
};
