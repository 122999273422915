import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import * as React from 'react';

export function useUpdateCellMutate(
    customField: SegmentCustomFieldFragment,
    mutate: (value: any, slug: CustomFieldSlug) => Promise<any>
) {
    return React.useCallback(
        (value: any) => mutate(value, customField.slug),
        [customField, mutate]
    );
}
