import Chart, { ChartDataset } from 'chart.js/auto';
import { Box } from 'common/src/designSystem/components/box';
import { uniqueId } from 'lodash-es';
import * as React from 'react';

interface IBarChartProps {
    datasets: ChartDataset[];
    labels: string[];
}

export const BarChart = (props: IBarChartProps) => {
    const chartId = React.useMemo(() => uniqueId('chart-'), []);
    const chartRef = React.useRef<any>(null);

    React.useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(document.getElementById(chartId) as any, {
            type: 'bar',
            options: {
                animation: false,
                maintainAspectRatio: false
            },
            data: {
                labels: props.labels,
                datasets: props.datasets
            }
        });
    }, [props.datasets, props.labels]);

    return (
        <Box height={1} width={1}>
            <canvas id={chartId} />
        </Box>
    );
};
