import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { NominalType } from 'common/src/util/nominalType';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { Checkbox } from '../designSystem/components/checkbox';
import { closeOtherDropdowns } from '../designSystem/components/dropdown/dropdown';
import { useRichTableContext } from './richTableContext';

interface IRichTableRowProps<T extends { id: TId }, TId extends NominalType<number, any>> {
    row: T;
    selectedColumns: PossibleColumn[];
}

export const RichTableRow = <T extends { id: TId }, TId extends NominalType<number, any>>(
    props: IRichTableRowProps<T, TId>
) => {
    const { getMassActionId, massActions, renderCell, reloadRow, showMassActions } =
        useRichTableContext();
    const state = massActions.states[`r${getMassActionId(props.row)}`]?.state ?? 'unchecked';
    const reload = React.useCallback(() => {
        reloadRow(props.row.id);
    }, [props.row.id, reloadRow]);

    return (
        <Row
            css={{
                background: state === 'checked' ? '$primary100' : 'white',
                cursor: 'pointer',
                '&:hover': {
                    background: state === 'checked' ? '$primary100' : '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {showMassActions && (
                <Cell justify="center" width={48}>
                    <Checkbox
                        state={state}
                        onClick={(newState, e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            massActions.toggleRow(`r${getMassActionId(props.row)}`, newState);
                        }}
                    />
                </Cell>
            )}

            {props.selectedColumns.map((column) => (
                <React.Fragment key={column.slug}>
                    {renderCell(column, props.row, reload)}
                </React.Fragment>
            ))}
        </Row>
    );
};
