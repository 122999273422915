import { CustomFieldSlug, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { Textarea } from '../../designSystem/components/textarea';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdateTextareaCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;

    mutate(value: string, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateTextareaCell = (props: IUpdateTextareaCellProps) => {
    const fieldService = useService(FieldService);
    const value = fieldService.getValueString(props.customField, props.fields);
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={value}
            mutate={mutate}
            reload={props.reload}
            renderInput={(updatedValue, setUpdatedValue) => (
                <Textarea
                    label={props.customField.name}
                    value={updatedValue}
                    onChange={setUpdatedValue}
                />
            )}
            renderValue={value}
        />
    );
};
