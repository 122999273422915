import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { getDelegationLink } from 'common/src/vo/delegation';
import * as React from 'react';

export const OrganizationDelegationsSpaceIntegration = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const [isLoading, setIsLoading] = React.useState(false);
    const link = getDelegationLink(organizationId);
    const iframe = `<iframe
      height="100%"
      width="100%"
      src="${link}"
      style="border: none;"></iframe>`.replace(/\s+/g, ' ');

    return (
        <>
            <Spacer height="7" />

            <Box color="gray700" fontWeight="medium">
                {translate('lien_de_l_espac_49634')}
            </Box>

            <Spacer height="1" />

            <Flex
                css={{
                    color: '$primary700',
                    cursor: 'pointer',
                    fontWeight: '$medium',
                    '&:hover': {
                        textDecoration: 'underline'
                    }
                }}
                gap="2"
                target="_blank"
                to={{ pathname: link }}
            >
                <Box>{link}</Box>

                <Box>
                    <I icon="arrow-up-right-from-square" />
                </Box>
            </Flex>

            <Spacer height="6" />

            <Box color="gray700" fontWeight="medium">
                {translate('code_html_pour_83166')}
            </Box>

            <Spacer height="1" />

            <Box
                css={{
                    '& pre': {
                        border: '1px solid $gray300',
                        borderRadius: '$2',
                        padding: '$3'
                    }
                }}
            >
                <pre>{iframe}</pre>
            </Box>

            <Spacer height="2" />

            <Flex>
                <Button
                    isLoading={isLoading}
                    leftIcon="copy"
                    onClick={() => {
                        setIsLoading(true);
                        copy(iframe);

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                    }}
                >
                    {translate('copier_le_code_99588')}
                </Button>
            </Flex>

            <Spacer height="6" />

            <Box color="gray700" fontWeight="medium">
                {translate('qr_code_du_lien_34278')}
            </Box>

            <Spacer height="1" />

            <Box height={100} width={100}>
                <img
                    height="100%"
                    src={`https://api.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}/barcode/qr?text=${encodeURIComponent(link)}&color=000000&height=100&width=100`}
                    width="100%"
                />
            </Box>
        </>
    );
};
