import { LimitSource } from 'common-front/src/accreditAssign/accreditationLimitSource';
import { AssignPopup } from 'common-front/src/components/assignPopup';
import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { Button } from 'common-front/src/designSystem/components/button';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useVolunteersRegistrationsMassAccreditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMassActions } from 'common-front/src/hooks/useMassActions';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationAvailableVolunteersRegistrationsQuery,
    AccreditationDisplay,
    AccreditationsSlotId,
    DelegationId,
    MassAssignStrategy,
    UsersInfoId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import { groupBy, mapValues, noop, sortBy } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAccreditationAvailableVolunteersRegistrationsQuery } from '../../generated/graphqlHooks';
import { UserOverlayEvent } from '../../users/overlay/userOverlay';

interface IAccreditationsAccreditComponentProps {
    event: AccreditationAvailableVolunteersRegistrationsQuery['event'];

    reload(): void;
}

const AccreditationsAccreditComponent = ({
    event,
    reload
}: IAccreditationsAccreditComponentProps) => {
    const {
        translate,
        history,
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    const { mutate: massAccredit, isLoading } = useVolunteersRegistrationsMassAccreditMutation();

    const [search, setSearch] = React.useState('');
    const [slotId, setSlotId] = React.useState(
        event.accreditation.slots.length > 1
            ? event.accreditation.slots[0].id
            : event.accreditation.hiddenSlotId
    );

    const volunteersRegistrations = React.useMemo(() => {
        const availableVolunteersRegistrations =
            event.accreditation.availableVolunteersRegistrations.filter((vr) =>
                event.accreditation.accreditationDisplay === AccreditationDisplay.Radio
                    ? !(
                          vr.isAccreditedTo &&
                          !isNonEmptyArray(
                              vr.accreditationsUsersInfos.filter(
                                  (as) => as.accreditationSlotId === slotId
                              )
                          )
                      )
                    : true
            );

        if (isNonEmptyString(search)) {
            return availableVolunteersRegistrations.flatMap((vr) =>
                vr.userInfo.name.toLowerCase().includes(search.toLowerCase()) ||
                vr.userInfo.email.includes(search.toLowerCase())
                    ? {
                          isAccredited: isNonEmptyArray(
                              vr.accreditationsUsersInfos.filter(
                                  (aui) => aui.accreditationSlotId === slotId
                              )
                          ),
                          ...vr
                      }
                    : []
            );
        } else {
            return availableVolunteersRegistrations.map((vr) => ({
                isAccredited: isNonEmptyArray(
                    vr.accreditationsUsersInfos.filter((aui) => aui.accreditationSlotId === slotId)
                ),
                ...vr
            }));
        }
    }, [event, search, slotId]);
    const slots = React.useMemo(
        () =>
            sortBy(
                event.accreditation.slots.map((slot) => ({
                    id: slot.id,
                    name: fullName(dateTimeService, slot, event.accreditation.name),
                    date: slot.date?.toMillis() ?? -1,
                    assignedResources: slot.assignedResources,
                    wishedResources: slot.wishedResources,
                    maxResources: slot.maxResources
                })),
                ['date', 'name']
            ),
        [event.accreditation.slots]
    );
    const volunteersRegistrationsMeta = React.useMemo(
        () =>
            volunteersRegistrations.map((volunteerRegistration) => {
                const da = volunteerRegistration.delegation?.accreditationsSlots.find(
                    (da) => da.accreditationSlot.id === slotId
                );
                const slot = slots.find((slot) => slot.id === slotId)!;

                const { assignedResources, limitIcon, limitSource, maxResources } =
                    getAccreditationLimits(da, slot);

                return {
                    id: volunteerRegistration.id,
                    delegationId: volunteerRegistration.delegation?.id,
                    assignedResources,
                    maxResources,
                    limitIcon,
                    limitSource,
                    isDisabled:
                        !volunteerRegistration.isAccreditedTo && maxResources
                            ? assignedResources >= maxResources
                            : undefined
                };
            }),
        [volunteersRegistrations, slotId]
    );

    const { numberOfSelected, selectAllState, selecteds, states, toggleRow, toggleSelectAll } =
        useMassActions<
            UsersInfoId,
            { delegationId: Emptyable<DelegationId>; isAccredited: boolean }
        >(
            Object.fromEntries(
                volunteersRegistrations.map((volunteerRegistration) => {
                    const { isDisabled } = volunteersRegistrationsMeta.find(
                        (vrm) => vrm.id === volunteerRegistration.id
                    )!;

                    return [
                        `vr${volunteerRegistration.userInfo.id}`,
                        {
                            id: volunteerRegistration.userInfo.id,
                            infos: {
                                delegationId: volunteerRegistration.delegation?.id,
                                isAccredited: volunteerRegistration.isAccredited
                            },
                            disabled: isDisabled,
                            state: volunteerRegistration.isAccreditedTo ? 'checked' : 'unchecked'
                        }
                    ];
                })
            )
        );
    const numberToAccredit = React.useMemo(
        () => selecteds.filter((s) => !s.infos!.isAccredited).length,
        [selecteds]
    );
    const numberToDeaccredit = React.useMemo(
        () => selecteds.filter((s) => s.infos!.isAccredited).length,
        [selecteds]
    );
    const delegationIdToNumberOfSelected = React.useMemo(
        () =>
            mapValues(
                groupBy(
                    selecteds.filter((s) => typeof s.infos!.delegationId === 'number'),
                    (s) => s.infos!.delegationId
                ),
                (s) => s.length
            ),
        [selecteds]
    );

    const isMaxExceeded = React.useMemo(
        () =>
            isNonEmptyArray(
                volunteersRegistrationsMeta.filter((vrm) =>
                    vrm.limitSource
                        ? vrm.limitSource === LimitSource.DelegationAccreditation &&
                          vrm.delegationId
                            ? delegationIdToNumberOfSelected[vrm.delegationId] > vrm.maxResources
                            : numberOfSelected > vrm.maxResources
                        : false
                )
            ),
        [volunteersRegistrationsMeta, delegationIdToNumberOfSelected, numberOfSelected]
    );
    const hasChangesToMake = numberToAccredit > 0 || numberToDeaccredit > 0;

    return (
        <>
            <AssignPopup
                button={
                    <Button
                        disabled={isMaxExceeded || !hasChangesToMake}
                        isLoading={isLoading}
                        onClick={async () => {
                            const usersInfosIds = Object.values(states)
                                .filter(({ state }) => state === 'checked')
                                .map(({ id }) => id);

                            await massAccredit({
                                eventId,
                                massAccredit: {
                                    accreditationsSlotsIds: [slotId],
                                    selecteds: { ids: usersInfosIds },
                                    strategy: MassAssignStrategy.Add
                                }
                            });

                            history.push(
                                AccreditationsPaths.ACCREDITATION({
                                    organizationId,
                                    eventId,
                                    accreditationId
                                })
                            );
                        }}
                    >
                        {!hasChangesToMake
                            ? translate('no_changes_made')
                            : numberToAccredit > 0 && numberToDeaccredit > 0
                              ? translate(
                                    'accredit_and_deaccredit_members',
                                    numberToAccredit,
                                    numberToDeaccredit
                                )
                              : numberToAccredit > 0
                                ? translate('accr_diter_1_13773', numberToAccredit)
                                : translate('deaccredit', numberToDeaccredit)}
                    </Button>
                }
                popup={{
                    category: translate('Accreditation'),
                    closePath: AccreditationsPaths.ACCREDITATION({
                        organizationId,
                        eventId,
                        accreditationId
                    }),
                    title: translate('accr_ditation_03515', event.accreditation.name)
                }}
            >
                {slots.length > 0 && (
                    <>
                        <Flex
                            css={{
                                background: 'white',
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs',
                                padding: '$5 $6'
                            }}
                            direction="column"
                            gap="1"
                        >
                            <Box
                                css={{ textTransform: 'uppercase' }}
                                font="gray500 textXs semiBold"
                            >
                                {translate('sur_quel_cr_nea_07475')}
                            </Box>

                            <RichSelect
                                isSearchVisible={true}
                                placeholder={translate('choisir_un_cr_n_50945')}
                                values={[slotId]}
                                onChange={(newSlotId: AccreditationsSlotId[]) => {
                                    if (isNonEmptyArray(newSlotId)) {
                                        setSlotId(newSlotId[0]);
                                    }
                                }}
                            >
                                {slots.map((slot) => (
                                    <option key={slot.id} value={slot.id}>
                                        {slot.name}
                                    </option>
                                ))}
                            </RichSelect>
                        </Flex>

                        <Spacer height="5" />
                    </>
                )}

                <TableFilters
                    filters={
                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_un_m_05904')}
                                value={search}
                                onChange={setSearch}
                            />
                        </Box>
                    }
                    headerCells={
                        <>
                            <HeaderCell css={{ paddingRight: 0 }} width={48}>
                                <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                            </HeaderCell>
                            <HeaderCell css={{ paddingLeft: '$2', flexGrow: 2 }}>
                                {translate('nom_du_membre_69353')}
                            </HeaderCell>
                            <HeaderCell>{translate('nombre_d_accr_d_02452')}</HeaderCell>
                            <HeaderCell>{translate('numero_disponible')}</HeaderCell>
                        </>
                    }
                    numberOfPages={1}
                    offset={0}
                    rows={volunteersRegistrations.map((volunteerRegistration) => {
                        const { maxResources, limitIcon, limitSource, isDisabled } =
                            volunteersRegistrationsMeta.find(
                                (vrm) => vrm.id === volunteerRegistration.id
                            )!;
                        const finalNumberOfSelected =
                            limitSource === LimitSource.DelegationAccreditation &&
                            volunteerRegistration.delegation?.id
                                ? delegationIdToNumberOfSelected[
                                      volunteerRegistration.delegation.id
                                  ]
                                : numberOfSelected;
                        const finalNumberOfAccreditations =
                            volunteerRegistration.numberOfAccreditations +
                            (states[`vr${volunteerRegistration.userInfo.id}`].state === 'checked'
                                ? volunteerRegistration.isAccredited
                                    ? 0
                                    : 1
                                : volunteerRegistration.isAccredited
                                  ? -1
                                  : 0);

                        return (
                            <Row
                                key={volunteerRegistration.id}
                                css={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.ACCREDITATION_ACCREDIT_USER_INFORMATIONS(
                                            organizationId,
                                            eventId,
                                            accreditationId,
                                            volunteerRegistration.userInfo.id
                                        )
                                    );
                                }}
                            >
                                <Cell css={{ paddingRight: 0 }} width={48}>
                                    <Checkbox
                                        disabled={isDisabled}
                                        state={
                                            states[`vr${volunteerRegistration.userInfo.id}`].state
                                        }
                                        onClick={(newState, e) => {
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation();

                                            toggleRow(
                                                `vr${volunteerRegistration.userInfo.id}`,
                                                newState
                                            );
                                        }}
                                    />
                                </Cell>
                                <Cell css={{ paddingLeft: '$2', flexGrow: 2 }}>
                                    <AvatarNameEmail
                                        hasName={isNonEmptyString(
                                            volunteerRegistration.userInfo.name
                                        )}
                                        userInfo={volunteerRegistration.userInfo}
                                    />
                                </Cell>
                                <Cell>{finalNumberOfAccreditations}</Cell>
                                {isNonEmptyArray(
                                    volunteersRegistrations.filter((vr) => vr.delegation)
                                ) && (
                                    <Cell>
                                        {maxResources !== Infinity && (
                                            <>
                                                <Box
                                                    css={{
                                                        color:
                                                            finalNumberOfSelected > maxResources
                                                                ? 'red'
                                                                : 'inherit'
                                                    }}
                                                >
                                                    {`${finalNumberOfSelected}/${maxResources}`}
                                                </Box>

                                                <Spacer width={'2'} />

                                                <Box
                                                    css={{
                                                        color:
                                                            finalNumberOfSelected > maxResources
                                                                ? 'red'
                                                                : 'gray500'
                                                    }}
                                                    fontSize="textXs"
                                                >
                                                    {limitIcon}
                                                </Box>
                                            </>
                                        )}
                                    </Cell>
                                )}
                            </Row>
                        );
                    })}
                    setOffset={noop}
                    title={
                        <Flex direction="column">
                            <Box font="gray900 textMd semiBold">
                                {translate('s_lectionnez_le_75158')}
                            </Box>

                            <Box font="gray500 textSm regular">
                                {translate('les_membres_pr_44896')}
                            </Box>
                        </Flex>
                    }
                    totalCount={volunteersRegistrations.length}
                />
            </AssignPopup>

            <Switch>
                <Route
                    path={HeaventPaths.ACCREDITATION_ACCREDIT_USER(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId',
                        ':userInfoId'
                    )}
                >
                    <UserOverlayEvent
                        getBasePath={(userInfoId, isRouteComponent) =>
                            HeaventPaths.ACCREDITATION_ACCREDIT_USER(
                                isRouteComponent ? ':organizationId' : organizationId,
                                isRouteComponent ? ':eventId' : eventId,
                                isRouteComponent ? ':accreditationId' : accreditationId,
                                userInfoId
                            )
                        }
                        onClose={() => {
                            history.push(
                                HeaventPaths.ACCREDITATION_ACCREDIT(
                                    organizationId,
                                    eventId,
                                    accreditationId
                                )
                            );
                        }}
                        onDelete={() => {
                            history.push(
                                HeaventPaths.ACCREDITATION_ACCREDIT(
                                    organizationId,
                                    eventId,
                                    accreditationId
                                )
                            );

                            reload();
                        }}
                        onUpdateState={reload}
                    />
                </Route>
            </Switch>
        </>
    );
};

export const AccreditationsAccredit = () => {
    const { eventId, accreditationId } = useParams();
    const { data, loader, reload } = useAccreditationAvailableVolunteersRegistrationsQuery({
        eventId,
        accreditationId
    });

    if (loader) {
        return loader;
    } else {
        return <AccreditationsAccreditComponent event={data.event} reload={reload} />;
    }
};
