import { DateTime } from 'luxon';
import { Event, EventState } from '../generated/types';
import { DateTimeService } from '../services/dateTimeService';
import { assertUnreachable } from '../util/assertUnreachable';
import { Emptyable } from '../util/emptyable';
import { LocaleFormats } from '../util/luxon';
import { ICoordinates, IGoogleCoordinates } from '../util/map';

export function getGoogleCenter(
    event: Emptyable<Pick<Event, 'latitude' | 'longitude'>>
): IGoogleCoordinates | undefined {
    if (typeof event?.latitude === 'number' && typeof event?.longitude === 'number') {
        return {
            lat: event.latitude,
            lng: event.longitude
        };
    } else {
        return undefined;
    }
}

export function getCenter(
    event: Emptyable<Pick<Event, 'latitude' | 'longitude'>>
): ICoordinates | undefined {
    if (typeof event?.latitude === 'number' && typeof event?.longitude === 'number') {
        return {
            latitude: event.latitude,
            longitude: event.longitude
        };
    } else {
        return undefined;
    }
}

export function startAtEndAtToString(
    dateTimeService: DateTimeService,
    startAt: DateTime,
    endAt: DateTime
): string {
    const format = LocaleFormats.DateOnly.MonthLong;

    if (startAt.equals(endAt)) {
        return dateTimeService.toLocaleString(startAt, format);
    } else if (startAt.startOf('month').equals(endAt.startOf('month'))) {
        return `${startAt.toFormat('LLLL dd')}–${endAt.toFormat('dd, yyyy')}`;
    } else if (startAt.startOf('year').equals(endAt.startOf('year'))) {
        return `${startAt.toFormat('LLLL dd')}–${dateTimeService.toLocaleString(endAt, format)}`;
    } else {
        return `${startAt.toFormat('LLLL dd, yyyy')}–${dateTimeService.toLocaleString(
            endAt,
            format
        )}`;
    }
}

export function getStateColor(state: EventState) {
    switch (state) {
        case EventState.Archived:
        case EventState.Done:
            return 'error';
        case EventState.Ongoing:
            return 'success';
        case EventState.Upcoming:
            return 'purple';
        default:
            return assertUnreachable(state);
    }
}
