import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationId,
    AccreditationProperty,
    AccreditationsCategory
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsCategoryCellInput } from './accreditationsCategoryCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsCategoryCellProps {
    accreditationId: AccreditationId;
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;

    reload(): void;
}

export const AccreditationsCategoryCell = (props: IAccreditationsCategoryCellProps) => (
    <AccreditationsUpdateCell
        accreditationId={props.accreditationId}
        initialValue={props.accreditationCategory.id}
        property={AccreditationProperty.Category}
        reload={props.reload}
        renderInput={(value, setValue) => (
            <AccreditationsCategoryCellInput setValue={setValue} value={value} />
        )}
        renderValue={() => (
            <Box
                css={{
                    ellipsis: true
                }}
                font="gray900 textSm medium"
                title={props.accreditationCategory.name}
                width={1}
            >
                {props.accreditationCategory.name}
            </Box>
        )}
    />
);
