import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { ColumnMappings } from './columnMappings';

export const MappingsFormBox = (
    props: React.ComponentProps<typeof ColumnMappings> & { modeSelection?: React.ReactNode }
) => {
    const translate = useTranslate();

    return (
        <FormBox
            hideToggle={true}
            initialIsOpen={true}
            subtitle={translate('_ditez_la_corre_24969')}
            title={translate('liste_des_champ_40104')}
        >
            {props.modeSelection}

            <Spacer height="5" />

            <ColumnMappings {...props} />
        </FormBox>
    );
};
