import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { Position, PositionProperty } from 'common/src/generated/types';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsNameCellProps {
    position: Pick<Position, 'id' | 'name'> & { numberOfSlots?: number };

    reload(): void;
}

export const PositionsNameCell = (props: IPositionsNameCellProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <PositionsUpdateCell
            initialValue={props.position.name}
            positionId={props.position.id}
            property={PositionProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_la_missi_64605')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() =>
                !isEditMode ? (
                    <Link
                        title={props.position.name}
                        to={PositionsPaths.POSITION({
                            organizationId,
                            eventId,
                            positionId: props.position.id
                        })}
                    >
                        <Box css={css} font="gray900 textSm medium" width={1}>
                            {props.position.name}
                        </Box>
                    </Link>
                ) : (
                    <Box
                        css={css}
                        font="gray900 textSm medium"
                        title={props.position.name}
                        width={1}
                    >
                        {props.position.name}
                    </Box>
                )
            }
        />
    );
};
