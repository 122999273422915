import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DelegationId, EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { DelegationInputService } from 'common/src/input/delegationInput';
import { toggle } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useUsersInfosMassDelegationAddMutation,
    useVolunteersRegistrationsMassDelegationAddMutation
} from '../../generated/graphqlHooks';
import {
    AvailableDelegationMembersEvent,
    AvailableDelegationMembersOrganization
} from './availableDelegationMembers';

interface IDelegationAddMembersModalProps {
    delegationId: DelegationId;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onClose(): void;
    reload(): void;
}

export const DelegationAddMembersModal = ({
    delegationId,
    eventId,
    organizationId,
    onClose,
    reload
}: IDelegationAddMembersModalProps) => {
    const translate = useTranslate();
    const delegationInputService = useService(DelegationInputService);
    const [email, emailDebounced, setEmail] = useStateDebounce('');
    const { mutate: volunteersRegistrationsMassDelegationAdd } =
        useVolunteersRegistrationsMassDelegationAddMutation();
    const { mutate: usersInfosMassDelegationAdd } = useUsersInfosMassDelegationAddMutation();

    return (
        <FormModal
            buttonText={translate('ajouter_un_memb_33261')}
            icon="sliders"
            initialValues={{
                usersInfosIds: []
            }}
            schema={delegationInputService.adminAddSchema()}
            size="md"
            subtitle={
                eventId ? translate('ajouter_un_memb_95658') : translate('ajouter_un_memb_89696')
            }
            title={translate('add_member_to_delegation')}
            onClose={onClose}
            onSubmit={async (values: { usersInfosIds: UsersInfoId[] }) => {
                if (eventId) {
                    await volunteersRegistrationsMassDelegationAdd({
                        eventId,
                        delegationId,
                        selecteds: { ids: values.usersInfosIds }
                    });
                } else {
                    await usersInfosMassDelegationAdd({
                        organizationId,
                        delegationId,
                        selecteds: { ids: values.usersInfosIds }
                    });
                }

                onClose();
                reload();
            }}
        >
            {({ form, values }) => {
                const changeUsersInfosIds = (userInfoId: UsersInfoId) => {
                    form.change('usersInfosIds', toggle(values.usersInfosIds, userInfoId));
                };
                return (
                    <>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_m_05904')}
                            value={email}
                            onChange={setEmail}
                        />

                        <Spacer height="6" />

                        <FormErrors />

                        <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column">
                            {eventId ? (
                                <AvailableDelegationMembersEvent
                                    delegationId={delegationId}
                                    eventId={eventId}
                                    nameOrEmail={emailDebounced}
                                    select={changeUsersInfosIds}
                                    usersInfosIds={values.usersInfosIds}
                                />
                            ) : (
                                <AvailableDelegationMembersOrganization
                                    delegationId={delegationId}
                                    nameOrEmail={emailDebounced}
                                    organizationId={organizationId}
                                    select={changeUsersInfosIds}
                                    usersInfosIds={values.usersInfosIds}
                                />
                            )}
                        </Flex>
                    </>
                );
            }}
        </FormModal>
    );
};
