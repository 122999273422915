import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Toggle } from './toggle';

interface IEmptyFormBoxProps {
    children: React.ReactNode;
}

export const EmptyFormBox = (props: IEmptyFormBoxProps) => (
    <Flex
        css={{
            background: 'white',
            borderRadius: '$2',
            boxShadow: '$sm',
            padding: '$6 $7'
        }}
        direction="column"
    >
        {props.children}
    </Flex>
);

interface IFormBoxProps {
    centerTitleAndSubtitle?: boolean;
    children: React.ReactNode;
    hideToggle?: boolean;
    initialIsOpen: boolean;
    subtitle?: string;
    title: string;

    onToggle?(isOpen: boolean): void;
}

export const FormBox = (props: IFormBoxProps) => {
    const { descId, labelId } = useUniqueIds();
    const [isOpen, setIsOpen] = React.useState(props.initialIsOpen);
    const toggle = React.useCallback(
        (value: boolean) => {
            setIsOpen(value);
            props.onToggle?.(value);
        },
        [props.onToggle, setIsOpen]
    );

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '$2',
                boxShadow: '$sm',
                padding: '$6 $7'
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={
                    isOpen && !props.centerTitleAndSubtitle
                        ? {
                              borderBottom: '1px solid $gray200',
                              paddingBottom: '$5'
                          }
                        : {}
                }
                gap="4"
            >
                <Flex
                    css={
                        props.centerTitleAndSubtitle
                            ? { flex: '1', alignItems: 'center', marginBottom: 'var(--space32)' }
                            : { flex: '1' }
                    }
                    direction="column"
                    gap="1"
                >
                    <Box color="gray900" fontSize="textMd" fontWeight="semiBold" id={labelId}>
                        {props.title}
                    </Box>

                    {isNonEmptyString(props.subtitle) && (
                        <Box
                            color="gray500"
                            dangerouslySetInnerHTML={{ __html: props.subtitle }}
                            id={descId}
                        />
                    )}
                </Flex>

                {!props.hideToggle && (
                    <Box>
                        <Toggle
                            descId={descId}
                            labelId={labelId}
                            size="md"
                            value={isOpen}
                            onChange={toggle}
                        />
                    </Box>
                )}
            </Flex>

            {isOpen && (
                <>
                    {!props.centerTitleAndSubtitle && <Spacer height="5" />}

                    {props.children}
                </>
            )}
        </Flex>
    );
};
