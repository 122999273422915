import { IconSelect } from 'common-front/src/designSystem/components/iconSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Position, PositionProperty } from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsIconCellProps {
    position: Pick<Position, 'id' | 'icon'>;

    reload(): void;
}

export const PositionsIconCell = (props: IPositionsIconCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            cellCss={{
                flex: '0 100px'
            }}
            initialValue={props.position.icon}
            positionId={props.position.id}
            property={PositionProperty.Icon}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <IconSelect
                    label={translate('Icon')}
                    renderOnPortal={true}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() => <I icon={fromIconVO(props.position.icon)} />}
        />
    );
};
