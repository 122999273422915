import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { AccreditationsAccreditationSlotFragment } from 'common/src/generated/types';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { pick } from 'lodash-es';
import * as React from 'react';
import { useAccreditationSlotUpdateMutation } from '../../../generated/graphqlHooks';

interface IAccreditationSlotNameCellProps {
    accreditationSlot: AccreditationsAccreditationSlotFragment;

    reload(): void;
}

export const AccreditationSlotNameCell = (props: IAccreditationSlotNameCellProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const { mutate } = useAccreditationSlotUpdateMutation();
    const name = React.useMemo(
        () => (isNonEmptyString(props.accreditationSlot.name) ? props.accreditationSlot.name : '-'),
        [props.accreditationSlot]
    );
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <UpdateCell
            initialValue={props.accreditationSlot.name}
            mutate={async (name) =>
                mutate({
                    eventId,
                    accreditationSlotId: props.accreditationSlot.id,
                    accreditationSlot: {
                        ...pick(props.accreditationSlot, ['id', 'date', 'maxResources']),
                        name
                    }
                })
            }
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_du_cr_neau_54351')}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={() =>
                isEditMode ? (
                    <Box css={css} font="gray900 textSm medium" title={name} width={1}>
                        {name}
                    </Box>
                ) : (
                    <Link
                        title={name}
                        to={AccreditationsPaths.ACCREDITATION_SLOT({
                            organizationId,
                            eventId,
                            accreditationId: props.accreditationSlot.accreditation.id,
                            accreditationSlotId: props.accreditationSlot.id
                        })}
                    >
                        <Box css={css} font="gray900 textSm medium" title={name} width={1}>
                            {name}
                        </Box>
                    </Link>
                )
            }
        />
    );
};
