import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomFieldSlug, FieldType } from 'common/src/generated/types';
import { DocumentElement } from 'common/src/vo/documentBuilder';
import { produce } from 'immer';
import { difference } from 'lodash-es';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

interface IDocumentBuilderLeftPanelElementProps {
    element: DocumentElement;
}

export const DocumentBuilderLeftPanelElement = (props: IDocumentBuilderLeftPanelElementProps) => {
    const { translate } = useHeavent();
    const { accreditationsOptions, customFields, positionsOptions, updateElementProperty } =
        useDocumentBuilderContext();
    const conditionsOptions = React.useMemo(
        () =>
            customFields
                .filter(
                    (cf) => cf.fieldType === FieldType.Checkbox || cf.fieldType === FieldType.Select
                )
                .map((cf) => (
                    <option key={cf.slug} value={cf.slug}>
                        {cf.name}
                    </option>
                )),
        [customFields]
    );
    const conditionsCustomFields = props.element.conditionsCustomFields || [];
    const customFieldsSlugs = conditionsCustomFields.map((cf) => cf.slug);
    const onCustomFieldsChange = (newCustomFieldsSlugs: CustomFieldSlug[]) => {
        const toAddSlugs = difference(newCustomFieldsSlugs, customFieldsSlugs);
        const toAddFields = customFields.filter((cf) => toAddSlugs.includes(cf.slug));
        const toRemove = difference(customFieldsSlugs, newCustomFieldsSlugs);
        const newConditionsCustomFields = conditionsCustomFields
            .filter((cf) => !toRemove.includes(cf.slug))
            .concat(
                toAddFields.map((cf) => ({
                    slug: cf.slug,
                    value: cf.fieldType === FieldType.Checkbox ? true : []
                }))
            );

        updateElementProperty(
            props.element.id,
            'conditionsCustomFields',
            newConditionsCustomFields
        );
    };
    const onValueChange = (newValue: any, index: number) => {
        const newConditionsCustomFields = produce(
            conditionsCustomFields,
            (currentConditionsCustomFields) => {
                currentConditionsCustomFields[index].value = newValue;
            }
        );

        updateElementProperty(
            props.element.id,
            'conditionsCustomFields',
            newConditionsCustomFields
        );
    };
    const selectedCustomFields = customFields.filter((cf) => customFieldsSlugs.includes(cf.slug));

    return (
        <>
            <Accordion css={{ padding: '$4 $6' }} title={translate('apparence_75510')}>
                <Flex direction="column" gap="4">
                    <Flex gap="4">
                        <TextInput
                            label={translate('largeur_54184')}
                            rightText={props.element.width <= 1 ? '%' : 'px'}
                            shouldParseAsInt={true}
                            value={props.element.width}
                            onChange={(newWidth) => {
                                updateElementProperty(props.element.id, 'width', newWidth);
                            }}
                        />

                        <TextInput
                            label={translate('hauteur_56576')}
                            rightText={props.element.height <= 1 ? '%' : 'px'}
                            shouldParseAsInt={true}
                            value={props.element.height}
                            onChange={(newHeight) => {
                                updateElementProperty(props.element.id, 'height', newHeight);
                            }}
                        />
                    </Flex>

                    <Flex gap="4">
                        <TextInput
                            label="X"
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.left}
                            onChange={(newLeft) => {
                                updateElementProperty(props.element.id, 'left', newLeft);
                            }}
                        />

                        <TextInput
                            label="Y"
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.top}
                            onChange={(newTop) => {
                                updateElementProperty(props.element.id, 'top', newTop);
                            }}
                        />
                    </Flex>

                    <TextInput
                        hint={translate('la_position_z_d_29473')}
                        label={translate('position_z_du_c_46647')}
                        shouldParseAsInt={true}
                        value={props.element.zIndex}
                        onChange={(newZIndex) => {
                            updateElementProperty(props.element.id, 'zIndex', newZIndex);
                        }}
                    />

                    <ToggleText
                        value={props.element.hasRotate}
                        onChange={(newhasRotate) => {
                            updateElementProperty(props.element.id, 'hasRotate', newhasRotate);
                        }}
                    >
                        {translate('faire_pivoter_06431')}
                    </ToggleText>

                    {props.element.hasRotate && (
                        <TextInput
                            label={translate('degr_79492')}
                            rightText="deg"
                            shouldParseAsInt={true}
                            value={props.element.rotateDegree}
                            onChange={(newRotateDegree) => {
                                updateElementProperty(
                                    props.element.id,
                                    'rotateDegree',
                                    newRotateDegree
                                );
                            }}
                        />
                    )}

                    {props.element.type === 'text' && (
                        <>
                            <ToggleText
                                value={props.element.hasBackground}
                                onChange={(newHasBackground) => {
                                    updateElementProperty(
                                        props.element.id,
                                        'hasBackground',
                                        newHasBackground
                                    );
                                }}
                            >
                                {translate('afficher_un_arr_96006')}
                            </ToggleText>

                            {props.element.hasBackground && (
                                <ColorInput
                                    label={translate('couleur_de_fond_75677')}
                                    value={props.element.backgroundColor}
                                    onChange={(newColor) => {
                                        updateElementProperty(
                                            props.element.id,
                                            'backgroundColor',
                                            newColor
                                        );
                                    }}
                                />
                            )}
                        </>
                    )}

                    <Flex gap="4">
                        <TextInput
                            label={translate('taille_de_la_bo_84060')}
                            rightText="px"
                            shouldParseAsInt={true}
                            value={props.element.borderSize}
                            onChange={(newBorderSize) => {
                                updateElementProperty(
                                    props.element.id,
                                    'borderSize',
                                    newBorderSize
                                );
                            }}
                        />

                        <ColorInput
                            label={translate('couleur_de_la_b_84227')}
                            value={props.element.borderColor}
                            onChange={(newBorderColor) => {
                                updateElementProperty(
                                    props.element.id,
                                    'borderColor',
                                    newBorderColor
                                );
                            }}
                        />
                    </Flex>

                    <TextInput
                        label={translate('rayon_de_la_bor_26070')}
                        rightText="px"
                        shouldParseAsInt={true}
                        value={props.element.borderRadius}
                        onChange={(newBorderRadius) => {
                            updateElementProperty(
                                props.element.id,
                                'borderRadius',
                                newBorderRadius
                            );
                        }}
                    />
                </Flex>
            </Accordion>

            <Accordion css={{ padding: '$4 $6' }} title={translate('condition_d_aff_68185')}>
                <Flex direction="column" gap="4">
                    <ToggleText
                        value={props.element.hasConditions}
                        onChange={(newHasConditions) => {
                            if (!newHasConditions) {
                                updateElementProperty(
                                    props.element.id,
                                    'accreditationsSlotsIds',
                                    []
                                );
                                updateElementProperty(props.element.id, 'positionsSlotsIds', []);
                            }

                            updateElementProperty(
                                props.element.id,
                                'hasConditions',
                                newHasConditions
                            );
                        }}
                    >
                        {translate('afficher_en_fon_47208')}
                    </ToggleText>

                    {props.element.hasConditions && (
                        <Flex
                            css={{
                                background: '$gray100',
                                border: '1px solid $gray200',
                                borderRadius: '$1',
                                boxShadow: '$xs',
                                padding: '$3 $4'
                            }}
                            direction="column"
                            gap="2"
                        >
                            <RichSelect
                                isSearchVisible={true}
                                label={translate('afficher_en_fon_89756')}
                                multiple={true}
                                values={props.element.accreditationsSlotsIds || []}
                                onChange={(newAccreditationsSlotsIds) => {
                                    updateElementProperty(
                                        props.element.id,
                                        'accreditationsSlotsIds',
                                        newAccreditationsSlotsIds
                                    );
                                }}
                            >
                                {accreditationsOptions}
                            </RichSelect>

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('afficher_en_fon_19925')}
                                multiple={true}
                                values={props.element.positionsSlotsIds || []}
                                onChange={(newPositionsSlotsIds) => {
                                    updateElementProperty(
                                        props.element.id,
                                        'positionsSlotsIds',
                                        newPositionsSlotsIds
                                    );
                                }}
                            >
                                {positionsOptions}
                            </RichSelect>

                            <RichSelect
                                isSearchVisible={true}
                                label={translate('afficher_en_fon_56078')}
                                multiple={true}
                                values={customFieldsSlugs}
                                onChange={onCustomFieldsChange}
                            >
                                {conditionsOptions}
                            </RichSelect>

                            {selectedCustomFields.map((cf, index) =>
                                cf.fieldType === FieldType.Checkbox ? (
                                    <Select
                                        key={cf.id}
                                        label={translate('afficher_si_la_42654', cf.name)}
                                        shouldParseAsBoolean={true}
                                        value={conditionsCustomFields[index].value}
                                        onChange={(newValue) => {
                                            onValueChange(newValue, index);
                                        }}
                                    >
                                        <option value="true">{translate('true')}</option>
                                        <option value="false">{translate('false')}</option>
                                    </Select>
                                ) : (
                                    <RichSelect
                                        key={cf.id}
                                        isSearchVisible={true}
                                        label={translate('afficher_si_la_42654', cf.name)}
                                        multiple={true}
                                        values={conditionsCustomFields[index].value || []}
                                        onChange={(newValues) => {
                                            onValueChange(newValues, index);
                                        }}
                                    >
                                        {cf.values.map(({ id, value }) => (
                                            <option key={id} value={id}>
                                                {value}
                                            </option>
                                        ))}
                                    </RichSelect>
                                )
                            )}
                        </Flex>
                    )}
                </Flex>
            </Accordion>
        </>
    );
};
