import { Page } from 'common-front/src/components/page/page';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSlugToCustomField } from 'common-front/src/hooks/useSlugToCustomField';
import { RichTable } from 'common-front/src/richTable/richTableContext';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import {
    columnsToIncludes,
    VolunteerDefaultColumns,
    VolunteersSegmentsService
} from 'common/src/vo/segments/volunteersSegmentsService';
import * as React from 'react';
import {
    executeVolunteerQuery,
    executeVolunteersQuery,
    useVolunteersSegmentsQuery
} from '../../../generated/graphqlHooks';
import { useLocalEventState } from '../../../hooks/useLocalState';
import { VolunteerCells } from '../../../volunteers/list/list/volunteerCells';
import { VolunteersActions } from '../../../volunteers/list/volunteersActions';

export const AccreditationMembers = () => {
    const {
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const volunteersSegmentsService = useService(VolunteersSegmentsService);
    const [columns, setColumns] = useLocalEventState('accreditationMembers.columns', [
        VolunteerDefaultColumns.Name,
        VolunteerDefaultColumns.Accreditations
    ]);
    const [limit, setLimit] = useLocalEventState(
        'accreditationMembers.limit',
        DEFAULT_PAGINATION_LIMIT
    );
    const [predicates, setPredicates] = useLocalEventState('accreditationMembers.predicates', []);
    const [sort, setSort] = useLocalEventState('accreditationMembers.sort', null);
    const { data } = useVolunteersSegmentsQuery({ organizationId, eventId });
    const customFields = React.useMemo(
        () => data.organization?.customFields.nodes ?? [],
        [data.organization]
    );
    const slugToCustomField = useSlugToCustomField(customFields);
    const filters = React.useMemo(() => {
        if (data.event) {
            return volunteersSegmentsService.getAccreditationMembersFilters(
                eventId,
                customFields,
                data.event
            );
        } else {
            return [];
        }
    }, [eventId, customFields, data.event]);
    const possibleColumns = React.useMemo(
        () => volunteersSegmentsService.getAccreditationMembersPossibleColumns(customFields),
        [customFields]
    );

    return (
        <Page.Content>
            <RichTable
                columns={columns}
                columnsSearch={[]}
                columnsToIncludes={columnsToIncludes}
                eventId={eventId}
                filters={filters}
                getMassActionId={(row: VolunteerRegistrationFragment) => row.userInfo.id}
                isRowDropdownVisible={false}
                limit={limit}
                loadDataList={executeVolunteersQuery}
                loadDataListParams={{ accreditationId }}
                loadRow={executeVolunteerQuery}
                possibleColumns={possibleColumns}
                predicates={predicates}
                renderActions={(massActions, totalCount, reload) => (
                    <VolunteersActions
                        massActions={massActions}
                        predicates={null}
                        showOnlyMassEdit={true}
                        totalNumberOfMembers={totalCount}
                        onMassEditSuccess={reload}
                    />
                )}
                renderCell={(column, row: VolunteerRegistrationFragment, reload) => (
                    <VolunteerCells
                        column={column}
                        customField={slugToCustomField.get(column.slug)}
                        eventId={eventId}
                        reloadRow={reload}
                        row={row}
                    />
                )}
                setColumns={setColumns}
                setLimit={setLimit}
                setPredicates={setPredicates}
                setSort={setSort}
                showEditColumns={true}
                showEditMode={true}
                showFilters={true}
                showMassActions={true}
                showSearchColumns={false}
                sort={sort}
            />
        </Page.Content>
    );
};
