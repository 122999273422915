import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { I } from 'common/src/designSystem/components/i';
import { CustomField } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export type CustomFieldsInCategory = [
    string,
    Array<Pick<CustomField, 'id' | 'name' | 'customFieldCategoryName'>>
];

interface IColumnMappingProps {
    column: {
        name: string;
        index: number;
        isRequired?: boolean;
    };
    defaultColumns: Record<string, string>;
    defaultFieldsOverride?: React.ReactNode;
    fields: CustomFieldsInCategory[];
}

export const ColumnMapping = ({
    column,
    defaultColumns,
    defaultFieldsOverride,
    fields
}: IColumnMappingProps) => {
    const translate = useTranslate();

    return (
        <>
            <TextInput
                css={{ fontWeight: column.isRequired ? 'bold' : 'inherit' }}
                state="disabled"
                value={column.name}
                onChange={() => undefined}
            />
            <I icon="arrow-right" />
            <RichSelect
                isSearchVisible={true}
                multiple={false}
                name={`input.mappings.${column.index}`}
                placeholder={translate('s_lectionnez_un_12007')}
            >
                {defaultFieldsOverride || (
                    <optgroup label={'Default'}>
                        {Object.values(defaultColumns).map((columnName) => (
                            <option key={`default-${columnName}`} value={columnName}>
                                {columnName}
                            </option>
                        ))}
                    </optgroup>
                )}

                {fields.map(([customFieldCategoryId, customFieldsInCategory]) => (
                    <optgroup
                        key={customFieldCategoryId}
                        label={customFieldsInCategory[0].customFieldCategoryName}
                    >
                        {customFieldsInCategory.map((field) => (
                            <option key={field.id} value={field.id.toString()}>
                                {field.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </RichSelect>
        </>
    );
};
