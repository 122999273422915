import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { range, rangeRight } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Select } from '../../../designSystem/components/select/select';

type DayMonthYear = {
    day: number;
    month: number;
    year: number;
};

interface IDayMonthYearSelectorProps {
    value: DayMonthYear;

    setValue(value: DayMonthYear): void;
}

export const DayMonthYearSelector = (props: IDayMonthYearSelectorProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const startOfYear = React.useMemo(() => DateTime.now().startOf('year'), []);

    return (
        <>
            <Spacer height="1" />

            <Flex>
                <Spacer width="6" />
                <Select
                    shouldParseAsInt={true}
                    value={props.value.day}
                    onChange={(day) => {
                        props.setValue({
                            ...props.value,
                            day
                        });
                    }}
                >
                    <option value="-1">{translate('jour_74089')}</option>
                    {range(1, 32).map((day) => (
                        <option key={day} value={day}>
                            {day}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Spacer height="1" />

            <Flex>
                <Spacer width="6" />
                <Select
                    shouldParseAsInt={true}
                    value={props.value.month}
                    onChange={(month) => {
                        props.setValue({
                            ...props.value,
                            month
                        });
                    }}
                >
                    <option value="-1">{translate('mois_10165')}</option>
                    {range(1, 13).map((month) => {
                        const monthStr = dateTimeService.toLocaleString(
                            startOfYear.set({ month }),
                            { month: 'long' }
                        );

                        return (
                            <option key={month} value={month}>
                                {monthStr}
                            </option>
                        );
                    })}
                </Select>
            </Flex>

            <Spacer height="1" />

            <Flex>
                <Spacer width="6" />
                <Select
                    shouldParseAsInt={true}
                    value={props.value.year}
                    onChange={(year) => {
                        props.setValue({
                            ...props.value,
                            year
                        });
                    }}
                >
                    <option value="-1">{translate('ann_e_38546')}</option>
                    {rangeRight(1900, startOfYear.year + 2).map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </Select>
            </Flex>

            <Spacer height="1" />
        </>
    );
};
