import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsColorCellProps {
    accreditation: Pick<Accreditation, 'id' | 'color'>;

    reload(): void;
}

export const AccreditationsColorCell = (props: IAccreditationsColorCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.color}
            property={AccreditationProperty.Color}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <ColorInput label={translate('Color')} value={value} onChange={setValue} />
            )}
            renderValue={() => (
                <Flex align="center" gap="3" width={1}>
                    <Box
                        css={{
                            background: props.accreditation.color,
                            borderRadius: '2px',
                            height: '24px',
                            width: '24px'
                        }}
                    />

                    <Box>{props.accreditation.color}</Box>
                </Flex>
            )}
        />
    );
};
