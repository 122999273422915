import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    PositionId,
    PositionsPositionFragment,
    PositionsSortAttributes,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { PositionDefaultColumns } from 'common/src/vo/segments/positionsSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { executePositionsPositionQuery, usePositionsQuery } from '../../../generated/graphqlHooks';
import { PositionRow } from './positionRow';

interface IPositionsListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const PositionsList = (props: IPositionsListProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { isEventAdmin, isPositionCategoryAdmin } = useEventContext();
    const {
        columns,
        columnsSearch,
        limit,
        massActions,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<PositionId, PositionsSortAttributes>();
    const includes = React.useMemo(
        () => ({
            includeCheckedInRate: columns.includes(PositionDefaultColumns.CheckedInRate),
            includeConditions: columns.includes(PositionDefaultColumns.Conditions),
            includeCreatedBy: columns.includes(PositionDefaultColumns.CreatedBy),
            includeTags: columns.includes(PositionDefaultColumns.Tags),
            includeTraces: false
        }),
        [columns]
    );
    const { data, isLoading, reload } = usePositionsQuery({
        ...includes,
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        columnsSearch: isNonEmptyArray(columnsSearch) ? columnsSearch : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.rows);
    const [positions, setPositions] = React.useState<PositionsPositionFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<PositionId, boolean>>({});
    const reloadPosition = React.useCallback(
        async (id: PositionId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { row }
            } = await executePositionsPositionQuery({ ...includes, eventId, id }, await getToken());

            setPositions((ps) => ps.map((p) => (p.id === id ? row : p)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setPositions]
    );

    React.useEffect(() => {
        setPositions(data.event?.rows.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    React.useEffect(() => {
        massActions.setStates(
            Object.fromEntries(
                (data.event?.rows.nodes ?? []).map((position) => [
                    `p${position.id}`,
                    { id: position.id, state: 'unchecked' }
                ])
            )
        );
    }, [data.event, massActions.setStates]);

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        {isEventAdmin && (
                            <HeaderCell justify="center" width={48}>
                                <Checkbox
                                    state={massActions.selectAllState}
                                    onClick={massActions.toggleSelectAll}
                                />
                            </HeaderCell>
                        )}

                        {selectedFields.map((field) =>
                            field.slug === PositionDefaultColumns.Id ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSortAttributes.Id}
                                    setSort={setSort}
                                    sort={sort}
                                    width={100}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Category ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSortAttributes.Category}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {translate('cat_gorie_00291')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Name ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSortAttributes.Name}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {translate('nom_de_la_missi_64605')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Slot ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSortAttributes.Slot}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {translate('cr_neau_11117')}
                                </HeaderCellSort>
                            ) : field.slug === PositionDefaultColumns.Resources ? (
                                <HeaderCell key={field.slug} width={256}>
                                    {translate('membres_11310')}
                                </HeaderCell>
                            ) : field.slug === PositionDefaultColumns.Icon ? (
                                <HeaderCell key={field.slug} width={100}>
                                    {field.name}
                                </HeaderCell>
                            ) : field.slug === PositionDefaultColumns.Acronym ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={PositionsSortAttributes.Acronym}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    {field.name}
                                </HeaderCellSort>
                            ) : field.isSortable ? (
                                <HeaderCellSort
                                    key={field.slug}
                                    attribute={field.slug}
                                    setSort={setSort}
                                    sort={sort}
                                >
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCellSort>
                            ) : (
                                <HeaderCell key={field.slug}>
                                    <Box css={{ ellipsis: true }} title={field.name}>
                                        {field.name}
                                    </Box>
                                </HeaderCell>
                            )
                        )}

                        {isPositionCategoryAdmin() && <HeaderCell width={100} />}
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            showLimits={true}
                            totalCount={totalCount}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    positions.map((position) => {
                        if (idToLoading[position.id]) {
                            return <RowSkeleton key={position.id} />;
                        } else {
                            return (
                                <PositionRow
                                    key={position.id}
                                    columns={selectedFields}
                                    customFields={props.customFields}
                                    position={position}
                                    reload={reload}
                                    reloadPosition={reloadPosition}
                                    state={
                                        massActions.states[`p${position.id}`]?.state ?? 'unchecked'
                                    }
                                    toggleRow={massActions.toggleRow}
                                />
                            );
                        }
                    })
                )}
            </TableGrid>
        </Flex>
    );
};
