import {
    CustomFieldSlug,
    PhoneInput as PhoneInputVO,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { PhoneInputService } from 'common/src/input/phoneInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { PhoneInput } from '../../designSystem/components/phoneInput';
import { UpdateCell } from './updateCell';
import { useUpdateCellMutate } from './useUpdateCellMutate';

interface IUpdatePhoneCellProps {
    customField: SegmentCustomFieldFragment;
    fields: Fields;

    mutate(value: any, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdatePhoneCell = (props: IUpdatePhoneCellProps) => {
    const fieldService = useService(FieldService);
    const phoneInput = useService(PhoneInputService);
    const valueString = fieldService.getValueString(props.customField, props.fields);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<PhoneInputVO>(props.customField, props.fields);
        const defaultValues = phoneInput.basePhoneInputDefault();

        return {
            country: value?.country || defaultValues.country,
            code: value?.code || defaultValues.code,
            number: value?.number || defaultValues.number
        };
    }, [props.fields]);
    const mutate = useUpdateCellMutate(props.customField, props.mutate);

    return (
        <UpdateCell
            initialValue={initialValue}
            mutate={mutate}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <PhoneInput label={props.customField.name} value={value} onChange={setValue} />
            )}
            renderValue={valueString}
        />
    );
};
