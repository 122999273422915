import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { Button } from 'common-front/src/designSystem/components/button';
import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { FloatInput } from 'common-front/src/designSystem/components/floatInput';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { Textarea } from 'common-front/src/designSystem/components/textarea';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomField } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { DocumentTextElement } from 'common/src/vo/documentBuilder';
import { round, sortBy } from 'lodash-es';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderLeftPanelElement } from './documentBuilderLeftPanelElement';

type CustomFieldVariable = Pick<CustomField, 'slug' | 'name'>;

interface IDocumentBuilderLeftPanelTextProps {
    text: DocumentTextElement;
}

export const DocumentBuilderLeftPanelText = (props: IDocumentBuilderLeftPanelTextProps) => {
    const { translate } = useHeavent();
    const { customFields, getSelectedPage, selectPage, updateElementProperty } =
        useDocumentBuilderContext();
    const customFieldsOptions = React.useMemo(
        () =>
            sortBy(
                (customFields as CustomFieldVariable[]).concat([
                    {
                        slug: 'email',
                        name: translate('Email')
                    },
                    {
                        slug: 'delegation.name',
                        name: translate('nom_de_la_d_l_g_28087')
                    },
                    {
                        slug: 'name',
                        name: translate('full_name')
                    },
                    {
                        slug: 'ticketId',
                        name: translate('code_barres_60437')
                    }
                ]),
                (cf) => cf.name.toLowerCase()
            ).map((cf) => {
                const value = `{user.${cf.slug}}`;
                const name = `${cf.name} {user.${cf.slug}}`;

                return (
                    <option key={cf.slug} value={value}>
                        {name}
                    </option>
                );
            }),
        [customFields]
    );
    const lineHeight = props.text.lineHeight || 1.4;
    const lineHeightPx = round(props.text.fontSize * lineHeight, 2);

    return (
        <Flex
            css={{ boxShadow: '$sm', overflow: 'hidden auto', paddingBottom: '$11' }}
            direction="column"
            height={1}
            width={1}
        >
            <Flex css={{ padding: '$2 $6' }}>
                <Button
                    color="invisible"
                    leftIcon="arrow-left"
                    onClick={() => {
                        selectPage(getSelectedPage()!.id);
                    }}
                >
                    {translate('retour_92202')}
                </Button>
            </Flex>

            <Accordion css={{ padding: '$4 $6' }} title={translate('initialisation_38640')}>
                <Flex direction="column" gap="4">
                    <TextInput
                        label={translate('nom_du_calque_77484')}
                        value={props.text.name}
                        onChange={(newName) => {
                            updateElementProperty(props.text.id, 'name', newName);
                        }}
                    />

                    <Textarea
                        label={translate('contenu_29668')}
                        value={props.text.text}
                        onChange={(newText) => {
                            updateElementProperty(props.text.id, 'text', newText);
                        }}
                    />

                    <RichSelect
                        isSearchVisible={true}
                        label={translate('ajouter_une_var_79098')}
                        values={[]}
                        onChange={(values) => {
                            if (isNonEmptyArray(values)) {
                                updateElementProperty(
                                    props.text.id,
                                    'text',
                                    `${props.text.text} ${values[0]}`.trim()
                                );
                            }
                        }}
                    >
                        {customFieldsOptions}
                    </RichSelect>
                </Flex>
            </Accordion>

            <Accordion css={{ padding: '$4 $6' }} title={translate('typographie_11343')}>
                <Flex direction="column" gap="4">
                    <RichSelect
                        isSearchVisible={true}
                        label="Police"
                        values={[props.text.fontFamily]}
                        onChange={(newFonts) => {
                            updateElementProperty(
                                props.text.id,
                                'fontFamily',
                                newFonts[0] || 'Open Sans'
                            );
                        }}
                    >
                        <option value="Arial">Arial</option>
                        <option value="Arimo">Arimo</option>
                        <option value="Barlow">Barlow</option>
                        <option value="Chivo">Chivo</option>
                        <option value="Fira Sans">Fira Sans</option>
                        <option value="Georgia">Georgia</option>
                        <option value="IBM Plex Sans">IBM Plex Sans</option>
                        <option value="Inter">Inter</option>
                        <option value="Lato">Lato</option>
                        <option value="Merriweather">Merriweather</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Noto Sans">Noto Sans</option>
                        <option value="Nunito">Nunito</option>
                        <option value="Open Sans">Open Sans</option>
                        <option value="Oswald">Oswald</option>
                        <option value="PT Sans">PT Sans</option>
                        <option value="Playfair Display">Playfair Display</option>
                        <option value="Poppins">Poppins</option>
                        <option value="Raleway">Raleway</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Rubik">Rubik</option>
                    </RichSelect>

                    <Flex gap="4">
                        <TextInput
                            label={translate('taille_de_la_po_58427')}
                            shouldParseAsInt={true}
                            value={props.text.fontSize}
                            onChange={(newFontSize) => {
                                updateElementProperty(props.text.id, 'fontSize', newFontSize);
                            }}
                        />

                        <FloatInput
                            hint={translate('soit_1_px_73099', lineHeightPx)}
                            label={translate('hauteur_de_lign_08120')}
                            value={lineHeight}
                            onChange={(newLineHeight) => {
                                updateElementProperty(props.text.id, 'lineHeight', newLineHeight);
                            }}
                        />
                    </Flex>

                    <Flex gap="4">
                        <ColorInput
                            label={translate('couleur_du_text_32021')}
                            value={props.text.color}
                            onChange={(newColor) => {
                                updateElementProperty(props.text.id, 'color', newColor);
                            }}
                        />

                        <Select
                            label={translate('graisse_de_la_p_41139')}
                            shouldParseAsInt={true}
                            value={props.text.fontWeight}
                            onChange={(newFontWeight) => {
                                updateElementProperty(props.text.id, 'fontWeight', newFontWeight);
                            }}
                        >
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                            <option value="700">700</option>
                            <option value="800">800</option>
                            <option value="900">900</option>
                        </Select>
                    </Flex>

                    <Flex direction="column" gap="1">
                        <Box font="gray700 textSm medium">{translate('alignement_54247')}</Box>

                        <Flex gap="4">
                            <Flex>
                                <Button
                                    color={props.text.justify === 'start' ? 'primary' : 'white'}
                                    css={{
                                        borderBottomRightRadius: '0',
                                        borderTopRightRadius: '0'
                                    }}
                                    leftIcon="align-left"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'justify', 'start');
                                    }}
                                />
                                <Button
                                    color={props.text.justify === 'center' ? 'primary' : 'white'}
                                    css={{
                                        borderLeft: 'none',
                                        borderRadius: '0',
                                        borderRight: 'none'
                                    }}
                                    leftIcon="align-center"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'justify', 'center');
                                    }}
                                />
                                <Button
                                    color={props.text.justify === 'end' ? 'primary' : 'white'}
                                    css={{
                                        borderBottomLeftRadius: '0',
                                        borderTopLeftRadius: '0'
                                    }}
                                    leftIcon="align-right"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'justify', 'end');
                                    }}
                                />
                            </Flex>

                            <Flex>
                                <Button
                                    color={props.text.align === 'start' ? 'primary' : 'white'}
                                    css={{
                                        borderBottomRightRadius: '0',
                                        borderTopRightRadius: '0'
                                    }}
                                    leftIcon="objects-align-top"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'align', 'start');
                                    }}
                                />
                                <Button
                                    color={props.text.align === 'center' ? 'primary' : 'white'}
                                    css={{
                                        borderLeft: 'none',
                                        borderRadius: '0',
                                        borderRight: 'none'
                                    }}
                                    leftIcon="objects-align-center-vertical"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'align', 'center');
                                    }}
                                />
                                <Button
                                    color={props.text.align === 'end' ? 'primary' : 'white'}
                                    css={{
                                        borderBottomLeftRadius: '0',
                                        borderTopLeftRadius: '0'
                                    }}
                                    leftIcon="objects-align-bottom"
                                    onClick={() => {
                                        updateElementProperty(props.text.id, 'align', 'end');
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Select
                        label={translate('transformation_09363')}
                        value={props.text.textTransform}
                        onChange={(newTextTransform) => {
                            updateElementProperty(props.text.id, 'textTransform', newTextTransform);
                        }}
                    >
                        <option value="none">{translate('aucune_76773')}</option>
                        <option value="capitalize">Capitalize</option>
                        <option value="uppercase">Uppercase</option>
                        <option value="lowercase">Lowercase</option>
                    </Select>
                </Flex>
            </Accordion>

            <DocumentBuilderLeftPanelElement element={props.text} />
        </Flex>
    );
};
