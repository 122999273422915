import { Blank } from 'common-front/src/components/blank/blank';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SelectSegment } from 'common-front/src/segments/massActions/selectSegment';
import { SearchRow } from 'common-front/src/segments/searchRow';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { SegmentTableHeader } from 'common-front/src/segments/segmentTableHeader';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationEventLevel, SegmentCustomFieldFragment } from 'common/src/generated/types';
import * as React from 'react';
import { DelegationsActions } from '../../../delegations/list/delegationsActions';
import { DelegationsList } from '../../../delegations/list/delegationsList';
import { CommunityDelegationsHeader } from './communityDelegationsHeader';

interface ICommunityDelegationsContentProps {
    customFields: SegmentCustomFieldFragment[];
    numberOfDelegations: number;
}

export const CommunityDelegationsContent = (props: ICommunityDelegationsContentProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { limit, segment } = useSegmentsContext();

    return (
        <>
            <CommunityDelegationsHeader segment={segment} />

            {props.numberOfDelegations === 0 ? (
                <Box css={{ flex: '1', overflow: 'hidden' }}>
                    <Blank
                        imageSrc={Svgs.AccreditationsBlank}
                        title={translate('vous_n_avez_pas_32103')}
                    />
                </Box>
            ) : (
                <>
                    <Spacer height="7" />

                    <SegmentTableHeader
                        searchRow={
                            <SearchRow
                                actions={<DelegationsActions />}
                                searchPlaceholder={translate('rechercher_une_31213')}
                                showEditColumns={true}
                                showEditMode={true}
                                showSearchColumns={false}
                            />
                        }
                        selectSegment={
                            <SelectSegment
                                pageSelectedText={translate('les_1_d_l_gat_27248', limit)}
                                segmentSelectedText={translate(
                                    'les_1_d_l_gat_04594',
                                    segment.count,
                                    segment.name
                                )}
                            />
                        }
                    />

                    <DelegationsList
                        customFields={props.customFields}
                        eventId={null}
                        level={OrganizationEventLevel.Organization}
                        organizationId={organizationId}
                    />
                </>
            )}
        </>
    );
};
