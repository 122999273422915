import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAccreditationQuery } from '../../generated/graphqlHooks';
import { Accreditation } from './accreditation';

export const AccreditationLoader = () => {
    const {
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const { data, loader } = useAccreditationQuery({
        eventId,
        accreditationId
    });

    return (
        loader || (
            <Switch>
                <Route
                    exact
                    path={AccreditationsPaths.ACCREDITATION({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        accreditationId: ':accreditationId'
                    })}
                >
                    <Empty
                        path={AccreditationsPaths.ACCREDITATION_DASHBOARD({
                            organizationId,
                            eventId,
                            accreditationId
                        })}
                        replace={true}
                    />
                </Route>

                <Route>
                    <Accreditation accreditation={data.event.accreditation} />
                </Route>
            </Switch>
        )
    );
};
