import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { CheckboxText } from '../designSystem/components/checkbox';
import { Dropdown } from '../designSystem/components/dropdown/dropdown';
import { Menu } from '../designSystem/components/dropdown/menu';
import { Trigger } from '../designSystem/components/dropdown/trigger';
import { TextInput } from '../designSystem/components/textInput';
import { ToggleText } from '../designSystem/components/toggle';
import { useRichTableContext } from './richTableContext';

export const RichTableSearchRow = () => {
    const translate = useTranslate();
    const {
        columnsSearch,
        isEditMode,
        massActions,
        name,
        possibleColumns,
        reload,
        renderActions,
        setAreColumnsOpen,
        setColumnsSearch,
        setIsEditMode,
        setIsFilterOpen,
        setName,
        setNameDebounced,
        showEditColumns,
        showEditMode,
        showFilters,
        showMassActions,
        showSearchColumns,
        totalCount
    } = useRichTableContext();
    const showActions = showMassActions && massActions.numberOfSelected > 0;
    const searchableColumns = React.useMemo(
        () =>
            sortBy(
                possibleColumns.filter((c) => c.isSearchable),
                (c) => c.name
            ),
        [possibleColumns]
    );
    const searchInputCss = React.useMemo(
        () =>
            showSearchColumns
                ? {
                      '& .h-styled-input-container': {
                          borderBottomRightRadius: '0',
                          borderTopRightRadius: '0'
                      }
                  }
                : {},
        [showSearchColumns]
    );

    return (
        <Flex align="center" gap="4">
            <Flex width={320}>
                <TextInput
                    css={searchInputCss}
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_50038')}
                    value={name}
                    onChange={(newName: string) => {
                        setName(newName);
                        setNameDebounced(newName);
                    }}
                />

                {showSearchColumns && (
                    <Dropdown>
                        <Trigger>
                            <Button
                                color="white"
                                css={{
                                    borderLeft: 'none',
                                    borderBottomLeftRadius: '0',
                                    borderTopLeftRadius: '0'
                                }}
                                leftIcon="bars-filter"
                            />
                        </Trigger>

                        <Menu
                            css={{ padding: '0' }}
                            maxHeight={350}
                            placement="bottom-end"
                            width={320}
                        >
                            <Flex css={{ padding: '$4 $6' }} direction="column" gap="4">
                                <Box font="gray800 textMd medium">
                                    {translate('rechercher_sur_85243')}
                                </Box>

                                <Flex direction="column" gap="3">
                                    {searchableColumns.map(({ slug, name }) => (
                                        <CheckboxText
                                            key={slug}
                                            state={
                                                columnsSearch.includes(slug)
                                                    ? 'checked'
                                                    : 'unchecked'
                                            }
                                            onClick={(state) => {
                                                setColumnsSearch(
                                                    addOrRemove(
                                                        columnsSearch,
                                                        slug,
                                                        state === 'checked'
                                                    )
                                                );
                                            }}
                                        >
                                            {name}
                                        </CheckboxText>
                                    ))}
                                </Flex>
                            </Flex>
                        </Menu>
                    </Dropdown>
                )}
            </Flex>

            {showFilters && (
                <Button
                    color="gray"
                    leftIcon="bars-filter"
                    onClick={() => {
                        //  TODO setSelectedFilterPredicate(null);
                        setIsFilterOpen(true);
                    }}
                >
                    {translate('filtres_64574')}
                </Button>
            )}

            <Box css={{ flex: '1' }}>
                {/* TODO isAdmin && isSaveVisible && (
                    <Button
                        color="invisible"
                        onClick={() => {
                            setIsSaveSegmentVisible(true);
                        }}
                    >
                        {translate('enregistrer_la_22884')}
                    </Button>
                )*/}
            </Box>

            {!showActions && showEditColumns && (
                <Button
                    color="white"
                    leftIcon="gear"
                    onClick={() => {
                        setAreColumnsOpen(true);
                    }}
                >
                    {translate('_diter_les_colo_39630')}
                </Button>
            )}

            {!showActions && showEditMode && (
                <ToggleText value={isEditMode} onChange={setIsEditMode}>
                    {translate('mode_dition_93778')}
                </ToggleText>
            )}

            {showActions && renderActions?.(massActions, totalCount, reload)}
        </Flex>
    );
};
