import { BarChart } from 'common-front/src/charts/barChart';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { theme } from 'common/src/designSystem/components/stitches';
import { Accreditation, DelegationReportingQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditationSlot';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IDelegationReportingAccreditationProps {
    accreditation: Pick<Accreditation, 'id' | 'name' | 'hasSlots'>;
    event: DelegationReportingQuery['event'];
}

export const DelegationReportingAccreditation = (props: IDelegationReportingAccreditationProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const slots = React.useMemo(
        () =>
            sortBy(
                props.event.delegation.accreditationsSlots.filter(
                    (das) => das.accreditation.id === props.accreditation.id
                ),
                (das) => [
                    das.accreditationSlot.date?.toMillis() ?? 1,
                    das.accreditationSlot.name.toLowerCase()
                ]
            ),
        [props.event]
    );
    const labels = React.useMemo(() => {
        if (props.accreditation.hasSlots) {
            return slots.map((slot) =>
                fullName(dateTimeService, slot.accreditationSlot, '', {
                    includeAccreditationName: false
                })
            );
        } else {
            return [props.accreditation.name];
        }
    }, [props.accreditation, slots]);
    const datasets = React.useMemo(() => {
        const isWeezeventSynchronized = typeof props.event.weezevent?.id === 'number';
        const hasMax = slots.some((s) => typeof s.maxResources === 'number');
        const datasets = [
            {
                label: translate('souhait_es_78101'),
                data: slots.map((s) => s.wishedResources),
                backgroundColor: theme.colors.primary400.value
            },
            {
                label: translate('affect_es_00048'),
                data: slots.map((s) => s.assignedResources),
                backgroundColor: theme.colors.primary700.value
            }
        ];

        if (isWeezeventSynchronized) {
            datasets.push({
                label: translate('scann_es_13169'),
                data: slots.map((s) => s.scannedResources),
                backgroundColor: theme.colors.primary900.value
            });
        }

        if (hasMax) {
            datasets.push({
                label: translate('maximum_56225'),
                data: slots.map((s) => s.maxResources ?? 0),
                backgroundColor: theme.colors.gray200.value
            });
        }

        return datasets;
    }, [props.accreditation, slots]);

    return (
        <Box height={380} width={1}>
            <BarChart datasets={datasets} labels={labels} />
        </Box>
    );
};
