import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { customFieldTypeToIcon } from 'common-front/src/vo/customField';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CustomFieldsQuery, OrganizationId, SegmentId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { varietyToTranslation } from 'common/src/vo/customField';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';

interface ICustomFieldCellsProps {
    column: PossibleColumn;
    organizationId: OrganizationId;
    row: CustomFieldsQuery['organization']['rows']['nodes'][0];
    segmentId: SegmentId;
}

export const CustomFieldCells = (props: ICustomFieldCellsProps) => {
    const { history, translate } = useHeavent();

    return props.column.slug === 'name' ? (
        <Cell>
            <Flex direction="column" width={1}>
                <Box color="gray800" css={{ ellipsis: true, width: '100%' }} title={props.row.name}>
                    {props.row.name}
                </Box>
                {props.row.conditionCustomField && (
                    <Box
                        color="gray500"
                        css={{ ellipsis: true, width: '100%' }}
                        title={props.row.conditionCustomField.name}
                    >
                        {translate('d_pend_de_1_52961', props.row.conditionCustomField.name)}
                    </Box>
                )}
            </Flex>
        </Cell>
    ) : props.column.slug === 'category' ? (
        <Cell>{props.row.customFieldCategoryName}</Cell>
    ) : props.column.slug === 'fieldType' ? (
        <Cell width={250}>
            <Flex>
                <Box color="gray800" css={{ width: '18px' }}>
                    <I icon={customFieldTypeToIcon(props.row.fieldType, props.row.fieldProperty)} />
                </Box>

                <Spacer width="2" />

                <Box color="gray800">{translate(props.row.fieldType)}</Box>
            </Flex>
        </Cell>
    ) : props.column.slug === 'numberOfTimesUsed' ? (
        <Cell width={200}>
            {props.row.numberOfTimesUsed === 0 ? (
                props.row.numberOfTimesUsed
            ) : (
                <Box
                    color="primary700"
                    css={{
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                    onClick={() => {
                        history.push(
                            HeaventPaths.CUSTOM_FIELD_USAGE(
                                props.organizationId,
                                props.segmentId,
                                props.row.id
                            )
                        );
                    }}
                >
                    {props.row.numberOfTimesUsed}
                </Box>
            )}
        </Cell>
    ) : props.column.slug === 'state' ? (
        <Cell width={200}>
            {props.row.isPrivate ? (
                <Badge color="gray" leftIcon="lock">
                    {translate('Private')}
                </Badge>
            ) : (
                <Badge color="primary" leftIcon="globe">
                    {translate('Public')}
                </Badge>
            )}
        </Cell>
    ) : props.column.slug === 'values' ? (
        <Cell>{props.row.values.map(({ value }) => value).join(', ')}</Cell>
    ) : props.column.slug === 'variable' ? (
        <Cell>{`{user.${props.row.slug}}`}</Cell>
    ) : props.column.slug === 'variety' ? (
        <Cell>{varietyToTranslation(translate, props.row.variety)}</Cell>
    ) : props.column.slug === 'createdBy' ? (
        <Cell>
            {props.row.createdBy && (
                <AvatarNameEmail hasName={true} userInfo={props.row.createdBy} />
            )}
        </Cell>
    ) : (
        <Cell />
    );
};
