import {
    executeEventDelegationsMassEditInfosQuery,
    executeOrganizationMassEditInfosQuery
} from 'common-front/src/generated/graphqlHooks';
import { getToken } from 'common-front/src/util/aws/cognito';
import { DelegationId, EventId, Form, OrganizationId, TagsQuery } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IDelegationsContext {
    getEditPath(delegationId: DelegationId): string;
    getShowPath(delegationId: DelegationId): string;
    getForms(): Promise<Array<Pick<Form, 'id' | 'name'>>>;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
}

const DelegationsContext = React.createContext<IDelegationsContext>({} as IDelegationsContext);

interface IDelegationsContextProviderProps {
    children: React.ReactNode;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    getEditPath(delegationId: DelegationId): string;
    getShowPath(delegationId: DelegationId): string;
}

export const DelegationsContextProvider = (props: IDelegationsContextProviderProps) => {
    const [forms, setForms] = React.useState<Emptyable<Array<Pick<Form, 'id' | 'name'>>>>(null);
    const [tags, setTags] =
        React.useState<Emptyable<TagsQuery['organization']['tags']['nodes']>>(null);
    const getForms = React.useCallback(async () => {
        if (forms) {
            return forms;
        } else {
            if (props.eventId) {
                const eventInfos = await executeEventDelegationsMassEditInfosQuery(
                    { organizationId: props.organizationId, eventId: props.eventId },
                    await getToken()
                );

                setForms(eventInfos.event.forms);
                setTags(eventInfos.organization.tags.nodes);

                return eventInfos.event.forms;
            } else {
                const organizationInfos = await executeOrganizationMassEditInfosQuery(
                    { organizationId: props.organizationId },
                    await getToken()
                );

                setForms(organizationInfos.organization.forms);
                setTags(organizationInfos.organization.tags.nodes);

                return organizationInfos.organization.forms;
            }
        }
    }, [props.eventId, props.organizationId, forms, setForms]);
    const getTags = React.useCallback(async () => {
        if (tags) {
            return tags;
        } else {
            if (props.eventId) {
                const eventInfos = await executeEventDelegationsMassEditInfosQuery(
                    { organizationId: props.organizationId, eventId: props.eventId },
                    await getToken()
                );

                setForms(eventInfos.event.forms);
                setTags(eventInfos.organization.tags.nodes);

                return eventInfos.organization.tags.nodes;
            } else {
                const organizationInfos = await executeOrganizationMassEditInfosQuery(
                    { organizationId: props.organizationId },
                    await getToken()
                );

                setForms(organizationInfos.organization.forms);
                setTags(organizationInfos.organization.tags.nodes);

                return organizationInfos.organization.tags.nodes;
            }
        }
    }, [props.eventId, props.organizationId, tags, setTags]);

    return (
        <DelegationsContext.Provider
            value={{
                getEditPath: props.getEditPath,
                getShowPath: props.getShowPath,
                getForms,
                getTags
            }}
        >
            {props.children}
        </DelegationsContext.Provider>
    );
};

export function useDelegationsContext() {
    return React.useContext(DelegationsContext);
}
