import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CustomSlotsPeriodInput, Event } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { UpdateCustomSlotPeriodModal } from './updateCustomSlotPeriodModal';

interface IFormParametersAvailabilititesCustomSlotPeriodProps {
    customSlotPeriod: CustomSlotsPeriodInput;
    event: Pick<Event, 'startAt' | 'endAt'>;
    index: number;

    remove(): void;
    updateCustomSlotPeriod(customSlotPeriod: CustomSlotsPeriodInput, index: number): void;
}

export const FormParametersAvailabilititesCustomSlotPeriod = (
    props: IFormParametersAvailabilititesCustomSlotPeriodProps
) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$3 $4'
                }}
                gap="2"
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box font="gray900 textSm semiBold">
                        {isNonEmptyString(props.customSlotPeriod.name)
                            ? props.customSlotPeriod.name
                            : translate('p_riode_de_l_v_78032')}
                    </Box>

                    <Box color="gray500">
                        {props.customSlotPeriod.startDate
                            ? dateTimeService.toLocaleString(
                                  props.customSlotPeriod.startDate,
                                  LocaleFormats.DateOnly.WeekdayLongMonthLong
                              )
                            : translate('d_but_le_l_v_n_58366')}{' '}
                        -{' '}
                        {props.customSlotPeriod.endDate
                            ? dateTimeService.toLocaleString(
                                  props.customSlotPeriod.endDate,
                                  LocaleFormats.DateOnly.WeekdayLongMonthLong
                              )
                            : translate('fin_de_l_v_nem_88380')}{' '}
                        - {translate('creneau', props.customSlotPeriod.customSlots.length)}
                    </Box>
                </Flex>

                <Button
                    color="white"
                    leftIcon="pen"
                    size="sm"
                    onClick={() => {
                        setIsEditOpen(true);
                    }}
                />

                <Button color="white" leftIcon="trash-can" size="sm" onClick={props.remove} />
            </Flex>

            {isEditOpen && (
                <UpdateCustomSlotPeriodModal
                    customSlotPeriod={props.customSlotPeriod}
                    event={props.event}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (customSlotPeriod) => {
                        props.updateCustomSlotPeriod(customSlotPeriod, props.index);
                    }}
                />
            )}
        </>
    );
};
