import { getRecrewteerPrimaryColors } from 'common/src/designSystem/components/stitches';
import {
    ConditionsOperator,
    Icon,
    MapLayer,
    PositionInput,
    PositionsCategoryId
} from 'common/src/generated/types';
import { DateTime } from 'luxon';
import { parseFromString } from './xml';

type Styles = { [key: string]: string };

const parseStyles = (xml: Document): Styles =>
    Object.fromEntries(
        Array.from(xml.querySelectorAll('Style')).map((style: Element) => {
            const id = style.getAttribute('id');
            const [, , b1, b2, g1, g2, r1, r2] = (style.querySelector('color')?.textContent || '')
                .trim()
                .split('');

            return [`#${id}`, `#${r1}${r2}${g1}${g2}${b1}${b2}`];
        })
    );

const parsePlacemark = (
    placemark: Element,
    positionCategoryId: PositionsCategoryId,
    layer: MapLayer,
    startAt: DateTime,
    styles: Styles
): PositionInput => {
    const [longitude, latitude] = (placemark.querySelector('Point coordinates')?.textContent || '')
        .trim()
        .split(',');

    return {
        positionCategoryId,
        name: (placemark.querySelector('name')?.textContent || '').trim(),
        description: (placemark.querySelector('description')?.textContent || '').trim(),
        color:
            styles[`${placemark.querySelector('styleUrl')?.textContent || ''}-normal`] ||
            getRecrewteerPrimaryColors().primary700,
        icon: Icon.LocationDot,
        layer,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        tracesIds: [],
        slots: [
            {
                startDate: startAt,
                startTime: '08:00',
                endDate: startAt,
                endTime: '09:00',
                resources: 1
            }
        ],
        conditionsOperator: ConditionsOperator.And,
        positionsCustomFields: [],
        tagsIds: [],
        fields: {},
        leadersIds: []
    };
};

export function parseKml(
    kmlString: string,
    positionCategoryId: PositionsCategoryId,
    layer: MapLayer,
    startAt: DateTime
): PositionInput[] {
    const xml = parseFromString(kmlString);
    const styles = parseStyles(xml);
    const placemarks = Array.from(xml.querySelectorAll('Placemark'));

    return placemarks.map((p) => parsePlacemark(p, positionCategoryId, layer, startAt, styles));
}
