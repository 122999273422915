import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationReportingQuery } from 'common/src/generated/types';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { useDelegationReportingQuery } from '../../../generated/graphqlHooks';
import { DelegationReportingAccreditation } from './delegationReportingAccreditation';

interface IDelegationReportingComponentProps {
    event: DelegationReportingQuery['event'];
}

const DelegationReportingComponent = (props: IDelegationReportingComponentProps) => {
    const accreditations = React.useMemo(
        () =>
            sortBy(
                uniqBy(
                    props.event.delegation.accreditationsSlots.map((das) => das.accreditation),
                    (a) => a.id
                ),
                (a) => a.name.toLowerCase()
            ),
        [props.event]
    );

    return (
        <Flex direction="column" gap="4">
            {accreditations.map((accreditation) => (
                <DetailBox key={accreditation.id} title={accreditation.name}>
                    <DelegationReportingAccreditation
                        accreditation={accreditation}
                        event={props.event}
                    />
                </DetailBox>
            ))}
        </Flex>
    );
};

export const DelegationReporting = () => {
    const {
        params: { eventId, delegationId }
    } = useHeavent();
    const { data, loader } = useDelegationReportingQuery({ eventId, delegationId });

    return loader || <DelegationReportingComponent event={data.event} />;
};
