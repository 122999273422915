import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import * as React from 'react';

interface IVolunteerRegistrationPreassignedPositionsProps {
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationPreassignedPositions = (
    props: IVolunteerRegistrationPreassignedPositionsProps
) => {
    const badges = React.useMemo(
        () =>
            (props.volunteerRegistration?.preassignPositions || []).map((p) => ({
                id: p.id,
                color: 'gray',
                icon: null,
                text: p.name
            })),
        [props.volunteerRegistration]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
