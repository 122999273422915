import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { uniqBy } from 'lodash-es';
import * as React from 'react';

interface IVolunteerRegistrationPositionsCategoriesProps {
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationPositionsCategories = (
    props: IVolunteerRegistrationPositionsCategoriesProps
) => {
    const badges = React.useMemo(
        () =>
            uniqBy(
                props.volunteerRegistration?.positionsSlotsUsersInfos ?? [],
                (psui) => psui.positionCategory.id
            ).map((psui) => ({
                id: psui.positionCategory.id,
                color: 'gray',
                icon: null,
                text: psui.positionCategory.name
            })),
        [props.volunteerRegistration]
    );

    return (
        <Cell>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
