import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { getToken } from '../util/aws/cognito';

export function useCachedDataLoading<T, U, S>(
    loadData: (args: T, token: Emptyable<string>) => Promise<U>,
    loadDataParams: T,
    extractData: (result: U) => S
): () => Promise<S> {
    const [data, setData] = React.useState<Emptyable<S>>(null);

    return React.useCallback(async () => {
        if (data) {
            return data;
        } else {
            const result = await loadData(loadDataParams, await getToken());
            const _data = extractData(result);

            setData(_data);

            return _data;
        }
    }, [data, loadData, loadDataParams]);
}
