import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal/modal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { CustomDocumentInputService } from 'common/src/input/customDocumentInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString, slug as slugify, SlugReplacer } from 'common/src/util/string';
import * as React from 'react';
import { useCustomDocumentCreateMutation } from '../../generated/graphqlHooks';

export const CreateCustomDocumentModal = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useCustomDocumentCreateMutation();
    const customDocumentInput = useService(CustomDocumentInputService);
    const [name, _setName] = React.useState('');
    const [slug, _setSlug] = React.useState('');
    const [isSlugTouched, setIsSlugTouched] = React.useState(false);
    const setName = React.useCallback(
        (newName: string) => {
            _setName(newName);

            if (!isSlugTouched) {
                _setSlug(slugify(newName, SlugReplacer.Empty));
            }
        },
        [isSlugTouched, _setName, _setSlug]
    );
    const setSlug = React.useCallback(
        (newSlug: string) => {
            if (isNonEmptyString(newSlug)) {
                _setSlug(slugify(newSlug, SlugReplacer.Empty));
                setIsSlugTouched(true);
            } else {
                _setSlug('');
                setIsSlugTouched(false);
            }
        },
        [_setSlug, setIsSlugTouched]
    );

    return (
        <Modal.Root>
            <Modal.Trigger>
                <Button>{translate('cr_er_un_docume_92512')}</Button>
            </Modal.Trigger>

            <Modal.Portal>
                <Modal.Header icon="file">{translate('nouveau_documen_36722')}</Modal.Header>

                <Modal.Content gap="5">
                    <TextInput
                        label={translate('nom_du_document_22604')}
                        value={name}
                        onChange={setName}
                    />

                    <TextInput
                        hint={translate('ne_doit_conteni_53122')}
                        label={translate('nom_de_la_varia_81999')}
                        value={slug}
                        onChange={setSlug}
                    />
                </Modal.Content>

                <Modal.Buttons>
                    <Button
                        onClick={async () => {
                            const { customDocumentCreate } = await mutate({
                                eventId,
                                customDocument: {
                                    name,
                                    slug,
                                    populationsIds: [],
                                    configuration: customDocumentInput.getConfigurationDefault()
                                }
                            });

                            history.push(
                                HeaventPaths.EDIT_CUSTOM_DOCUMENT(
                                    organizationId,
                                    eventId,
                                    customDocumentCreate.id
                                )
                            );
                        }}
                    >
                        {translate('cr_er_le_docume_02657')}
                    </Button>
                </Modal.Buttons>
            </Modal.Portal>
        </Modal.Root>
    );
};
