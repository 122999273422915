import { AccreditationsMassEditModal } from 'common-front/src/components/massEdit/massEditModal';
import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useAccreditationsMassEditMutation } from 'common-front/src/generated/graphqlHooks';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import {
    AccreditationId,
    AccreditationsMassEditInput,
    AccreditationsSortAttributes
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useAccreditationsMassDeleteMutation } from '../../generated/graphqlHooks';

export const AccreditationsActions = () => {
    const translate = useTranslate();
    const { eventId, massActions, onMassEditSuccess, organizationId, predicates, reload, segment } =
        useSegmentsContext<AccreditationId, AccreditationsSortAttributes>();
    const { mutate: accreditationsMassEdit } = useAccreditationsMassEditMutation();
    const { mutate: accreditationsMassDelete } = useAccreditationsMassDeleteMutation();
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const selectedsInput = massActions.getSelectedsInput(predicates, null);
    const finalNumberOfSelected = massActions.isAllSelected
        ? segment.count
        : massActions.numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_une_prop_26724')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible && (
                <AccreditationsMassEditModal
                    eventId={eventId!}
                    mutate={(massEdit: AccreditationsMassEditInput) =>
                        accreditationsMassEdit({
                            eventId: eventId!,
                            massEdit
                        })
                    }
                    organizationId={organizationId}
                    selecteds={selectedsInput}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_31111')]}
                    text={translate('suppression_de_42723', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await accreditationsMassDelete({
                            eventId: eventId!,
                            selecteds: selectedsInput
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
