import { Button } from 'common-front/src/designSystem/components/button';
import { useVolunteersRegistrationsMassAccreditMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationState,
    MassAssignStrategy,
    UsersInfoId,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useVolunteersRegistrationsMassAccreditationStateUpdateMutation } from '../../generated/graphqlHooks';
import { useAccreditContext } from './accreditContext';

interface IAccreditButtonsProps {
    isEdit: boolean;
    userInfoId: UsersInfoId;
    volunteerRegistrationId: VolunteersRegistrationId;
}

export const AccreditButtons = (props: IAccreditButtonsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { accreditationsSlotsIds, showNextMember } = useAccreditContext();
    const { mutate: stateUpdate, isLoading: isStateUpdateLoading } =
        useVolunteersRegistrationsMassAccreditationStateUpdateMutation();
    const { mutate: massAccredit, isLoading: isAccreditLoading } =
        useVolunteersRegistrationsMassAccreditMutation();
    const onSuccess = React.useCallback(() => {
        if (props.isEdit) {
            history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
        } else {
            showNextMember();
        }
    }, [props.isEdit, showNextMember]);

    return (
        <Flex
            align="center"
            css={{
                borderTop: '1px solid $gray200',
                px: '$7'
            }}
            gap="4"
            height={1}
            justify="end"
            width={1}
        >
            <Box
                color="error700"
                css={{ cursor: 'pointer' }}
                onClick={async () => {
                    if (!isAccreditLoading && !isStateUpdateLoading) {
                        await stateUpdate({
                            eventId,
                            massStateUpdate: {
                                selecteds: { ids: [props.userInfoId] },
                                state: AccreditationState.Refused
                            }
                        });

                        onSuccess();
                    }
                }}
            >
                {translate('refuser_92819')}
            </Box>

            <Box css={{ flex: '1' }} />

            <Button
                color="primary"
                isLoading={isAccreditLoading}
                onClick={async () => {
                    if (
                        isNonEmptyArray(accreditationsSlotsIds) &&
                        !isAccreditLoading &&
                        !isStateUpdateLoading
                    ) {
                        await massAccredit({
                            eventId,
                            massAccredit: {
                                accreditationsSlotsIds,
                                selecteds: { ids: [props.userInfoId] },
                                strategy: MassAssignStrategy.Replace
                            }
                        });

                        onSuccess();
                    }
                }}
            >
                {props.isEdit ? translate('mettre_jour_l_11622') : translate('accr_diter_70543')}
            </Button>

            <Button color="white" onClick={onSuccess}>
                {props.isEdit ? translate('annuler_48254') : translate('accr_diter_plus_19053')}
            </Button>
        </Flex>
    );
};
