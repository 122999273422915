import { styled } from 'common/src/designSystem/components/stitches';
import { OrganizationCustomFieldsFrontQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { ColumnMapping, CustomFieldsInCategory } from './columnMapping';

const Grid = styled('div', {
    display: 'grid',
    gridTemplateColumns: '[column] 1fr auto [field] 1fr',
    gap: '$4 $5',
    alignItems: 'center'
});

const ColumnHeader = styled('div', {
    color: '$gray800',
    gridColumn: 'column',
    fontWeight: '$medium'
});

const FieldHeader = styled('div', {
    color: '$gray800',
    gridColumn: 'field',
    fontWeight: '$medium'
});

interface IColumnMappingsProps {
    columns: string[];
    customFields: OrganizationCustomFieldsFrontQuery['organization']['customFields']['nodes'];
    defaultColumns: Record<string, string>;
    defaultFieldsOverride?: React.ReactNode;
    requiredColumns: string[];
}

export const ColumnMappings = ({
    columns,
    customFields,
    defaultColumns,
    defaultFieldsOverride,
    requiredColumns
}: IColumnMappingsProps) => {
    const translate = useTranslate();
    const fields = React.useMemo(
        () =>
            Object.entries(groupBy(customFields, 'customFieldCategoryId')).map(
                ([categoryId, categoryFields]) => [
                    categoryId,
                    sortBy(categoryFields, (f) => f.name)
                ]
            ) as CustomFieldsInCategory[],
        [customFields]
    );

    return (
        <Grid>
            <ColumnHeader>{translate('titres_de_vos_c_31388')}</ColumnHeader>
            <FieldHeader>{translate('champs_de_formu_64126')}</FieldHeader>
            {columns.map((name, index) => (
                <ColumnMapping
                    key={index}
                    column={{ name, index, isRequired: requiredColumns.includes(name) }}
                    defaultColumns={defaultColumns}
                    defaultFieldsOverride={defaultFieldsOverride}
                    fields={fields}
                />
            ))}
        </Grid>
    );
};
