import { Cell } from 'common/src/designSystem/components/table/cell';
import { CustomFieldSlug, FieldType, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { Fields, FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { UpdateCheckboxCell } from './updateCheckboxCell';
import { UpdateDateCell } from './updateDateCell';
import { UpdateNumberCell } from './updateNumberCell';
import { UpdatePhoneCell } from './updatePhoneCell';
import { UpdateSelectCell } from './updateSelectCell';
import { UpdateTextareaCell } from './updateTextareaCell';
import { UpdateTextCell } from './updateTextCell';

interface IUpdateCustomFieldCellProps {
    customField?: Emptyable<SegmentCustomFieldFragment>;
    fields: Fields;

    mutate(value: any, slug: CustomFieldSlug): Promise<any>;
    reload(): void;
}

export const UpdateCustomFieldCell = (props: IUpdateCustomFieldCellProps) => {
    const fieldService = useService(FieldService);

    return props.customField?.fieldType === FieldType.Checkbox ? (
        <UpdateCheckboxCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Date ? (
        <UpdateDateCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Phone ? (
        <UpdatePhoneCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Select ||
      props.customField?.fieldType === FieldType.Country ||
      props.customField?.fieldType === FieldType.Language ||
      props.customField?.fieldType === FieldType.Sex ||
      props.customField?.fieldType === FieldType.Nationality ? (
        <UpdateSelectCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Text ? (
        <UpdateTextCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Textarea ? (
        <UpdateTextareaCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : props.customField?.fieldType === FieldType.Number ? (
        <UpdateNumberCell
            customField={props.customField}
            fields={props.fields}
            mutate={props.mutate}
            reload={props.reload}
        />
    ) : (
        <Cell>
            {props.customField
                ? fieldService.getValueString(props.customField, props.fields, {
                      fileReturnValue: 'name'
                  })
                : ''}
        </Cell>
    );
};
