import { executeTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { useCachedDataLoading } from 'common-front/src/hooks/useCachedDataLoading';
import {
    AccreditationsCategoriesQuery,
    EventId,
    OrganizationId,
    TagsQuery
} from 'common/src/generated/types';
import * as React from 'react';
import { executeAccreditationsCategoriesQuery } from '../../generated/graphqlHooks';

interface IAccreditationsContext {
    getAccreditationsCategories(): Promise<
        AccreditationsCategoriesQuery['event']['accreditationsCategories']
    >;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
}

const AccreditationsContext = React.createContext<IAccreditationsContext>(
    {} as IAccreditationsContext
);

interface IAccreditationsContextProviderProps {
    children: React.ReactNode;
    eventId: EventId;
    organizationId: OrganizationId;
}

export const AccreditationsContextProvider = (props: IAccreditationsContextProviderProps) => {
    const getAccreditationsCategories = useCachedDataLoading(
        executeAccreditationsCategoriesQuery,
        { eventId: props.eventId },
        (d) => d.event.accreditationsCategories
    );
    const getTags = useCachedDataLoading(
        executeTagsQuery,
        { organizationId: props.organizationId },
        (d) => d.organization.tags.nodes
    );

    return (
        <AccreditationsContext.Provider
            value={{
                getAccreditationsCategories,
                getTags
            }}
        >
            {props.children}
        </AccreditationsContext.Provider>
    );
};

export function useAccreditationsContext() {
    return React.useContext(AccreditationsContext);
}
