import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { PositionQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PositionParams, PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { PositionDashboard } from './dashboard/positionDashboard';
import { PositionMembers } from './members/positionMembers';
import { PositionHeader } from './positionHeader';

interface IPositionProps {
    position: PositionQuery['event']['position'];
}

export const Position = (props: IPositionProps) => {
    const {
        params: { organizationId, eventId, positionId },
        translate
    } = useHeavent();

    const getPositionParams = (isRouteComponent: boolean): PositionParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        positionId: isRouteComponent ? ':positionId' : positionId
    });

    return (
        <Page.Root>
            <PositionHeader position={props.position} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab path={PositionsPaths.POSITION_DASHBOARD(getPositionParams(false))}>
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab path={PositionsPaths.POSITION_MEMBERS(getPositionParams(false))}>
                        {translate('membres_125561', props.position.assignedResources)}
                    </Tab>
                </TabList>

                <TabPanel
                    path={[
                        PositionsPaths.POSITION_DASHBOARD(getPositionParams(true)),
                        HeaventPaths.POSITION_SLOT_MEMBERS(
                            ':organizationId',
                            ':eventId',
                            ':positionId',
                            ':positionSlotId'
                        )
                    ]}
                >
                    <PositionDashboard />
                </TabPanel>

                <TabPanel path={PositionsPaths.POSITION_MEMBERS(getPositionParams(true))}>
                    <PositionMembers />
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
