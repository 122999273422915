import { Button } from 'common-front/src/designSystem/components/button';
import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { Accept } from 'common-front/src/util/accept';
import { parseKml } from 'common-front/src/util/kml';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, PositionsCategoryId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useKmlEventInfosQuery, usePositionsCreateMutation } from '../../../generated/graphqlHooks';

interface IKmlImportProps {
    eventId: EventId;

    onClose(): void;
}

export const KmlImport = (props: IKmlImportProps) => {
    const translate = useTranslate();
    const { data, loader } = useKmlEventInfosQuery({ eventId: props.eventId });
    const { mutate, isLoading } = usePositionsCreateMutation();
    const [file, setFile] = React.useState<FileInputValue | undefined>();
    const [categoryId, setCategoryId] = React.useState<PositionsCategoryId>(
        -1 as PositionsCategoryId
    );
    const valid = isNonEmptyString(file?.content) && categoryId !== -1;

    return (
        <Modal size="md" onClose={props.onClose}>
            <Box color="primary600" fontSize="displayXs" textAlign="center">
                <I icon="cloud-arrow-up" />
            </Box>

            <Spacer height="7" />

            <Box color="gray900" fontSize="textLg" textAlign="center">
                {translate('importer_des_mi_32264')}
            </Box>

            <Spacer height="7" />

            {loader || (
                <>
                    <FileInput
                        accept={Accept.Kml}
                        format="string"
                        label={translate('fichier_kml_55657')}
                        placeholder={translate('s_lectionnez_un_75984')}
                        value={file}
                        onChange={(newFile) => {
                            setFile(newFile);
                        }}
                    />

                    <Spacer height="4" />

                    <Select
                        label={translate('cat_gorie_00291')}
                        shouldParseAsInt={true}
                        value={categoryId ?? -1}
                        onChange={(newCategoryId: number) => {
                            setCategoryId(newCategoryId as PositionsCategoryId);
                        }}
                    >
                        <option disabled value="-1">
                            {translate('choisissez_la_c_72356')}
                        </option>

                        {data.event.positionsCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </Select>
                </>
            )}

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    disabled={!valid}
                    isLoading={isLoading}
                    onClick={async () => {
                        if (file) {
                            await mutate({
                                eventId: props.eventId,
                                positions: parseKml(
                                    file.content,
                                    categoryId,
                                    data.event.layer,
                                    data.event.startAt
                                )
                            });

                            window.location.reload();
                        }
                    }}
                >
                    {translate('importer_90688')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
