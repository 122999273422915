import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { DkimStatus, OrganizationDomainsQuery, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useDomainDkimVerificationRetryMutation } from '../../../generated/graphqlHooks';
import { OrganizationEmailsDnsRow } from './organizationEmailsDnsRow';

interface IOrganizationEmailsDomainProps {
    domain: OrganizationDomainsQuery['organization']['domains'][0];
    organizationId: OrganizationId;

    reload(): void;
}

export const OrganizationEmailsDomain = (props: IOrganizationEmailsDomainProps) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useDomainDkimVerificationRetryMutation();

    return (
        <TableFilters
            filters={
                <>
                    {props.domain.status === DkimStatus.Failed && (
                        <Button
                            color="white"
                            isLoading={isLoading}
                            leftIcon="arrows-rotate"
                            size="sm"
                            onClick={async () => {
                                await mutate({
                                    organizationId: props.organizationId,
                                    domain: props.domain.name
                                });

                                props.reload();
                            }}
                        >
                            {translate('r_essayer_43543')}
                        </Button>
                    )}
                </>
            }
            headerCells={
                <>
                    <HeaderCell>{translate('nom_08311')}</HeaderCell>
                    <HeaderCell width={100}>{translate('type_35427')}</HeaderCell>
                    <HeaderCell>{translate('valeur_22437')}</HeaderCell>
                </>
            }
            numberOfPages={0}
            offset={0}
            rows={
                <>
                    {props.domain.dnsEntries.map((entry, index) => (
                        <OrganizationEmailsDnsRow
                            key={index}
                            name={entry.name}
                            type={entry.type}
                            value={entry.value}
                        />
                    ))}
                    <OrganizationEmailsDnsRow
                        name={`_dmarc.${props.domain.name}`}
                        type="TXT"
                        value="v=DMARC1; p=quarantine; adkim=r;"
                    />
                </>
            }
            setOffset={noop}
            title={
                <Flex align="center" gap="2">
                    <Box font="gray900 textMd semiBold">
                        {translate('liste_des_entr_35559', props.domain.name)}
                    </Box>

                    {props.domain.status === DkimStatus.Success && (
                        <Badge color="success" leftIcon="circle" leftIconStyle="solid">
                            {translate('vos_entr_es_dns_05106')}
                        </Badge>
                    )}

                    {props.domain.status === DkimStatus.Pending && (
                        <Badge color="warning" leftIcon="circle" leftIconStyle="solid">
                            {translate('vos_entr_es_dns_31271')}
                        </Badge>
                    )}

                    {props.domain.status === DkimStatus.Failed && (
                        <Badge color="error" leftIcon="circle" leftIconStyle="solid">
                            {translate('la_v_rification_65771')}
                        </Badge>
                    )}
                </Flex>
            }
            totalCount={0}
        />
    );
};
