import { AccreditationsSlotId, CustomFieldSlug, PositionsSlotId } from '../generated/types';
import { A4_SIZES, A6_SIZES, CB_SIZES } from '../util/pdf';
import { UUID } from '../util/uuid';

export type BaseElement = {
    id: UUID;
    accreditationsSlotsIds: AccreditationsSlotId[];
    borderColor: string;
    borderRadius: number;
    borderSize: number;
    conditionsCustomFields: Array<{ slug: CustomFieldSlug; value: any }>;
    hasConditions: boolean;
    hasRotate: boolean;
    height: number;
    left: number;
    name: string;
    positionsSlotsIds: PositionsSlotId[];
    rotateDegree: number;
    top: number;
    width: number;
    zIndex: number;
};

export type DocumentTextElement = BaseElement & {
    type: 'text';
    text: string;
    align: 'start' | 'end' | 'center';
    backgroundColor: string;
    color: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: number;
    hasBackground: boolean;
    justify: 'start' | 'end' | 'center';
    lineHeight: number;
    textTransform: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
};

export type DocumentImageElement = BaseElement & {
    type: 'image';
    source?: string;
};

export type DocumentElement = DocumentTextElement | DocumentImageElement;

export type DocumentPage = {
    id: UUID;
    name?: string;
    elements: DocumentElement[];
};

export type CustomDocument = {
    name: string;
    slug: string;
    populationsIds: number[];
    configuration: {
        height: number;
        pages: DocumentPage[];
        width: number;
    };
};

export function getFormat(height: number, width: number): 'a4' | 'a6' | 'cb' | 'custom' {
    return height === A4_SIZES['96dpi'].height && width === A4_SIZES['96dpi'].width
        ? 'a4'
        : height === A6_SIZES['96dpi'].height && width === A6_SIZES['96dpi'].width
          ? 'a6'
          : height === CB_SIZES['96dpi'].height && width === CB_SIZES['96dpi'].width
            ? 'cb'
            : 'custom';
}
