import {
    EventIdPathParam,
    OrganizationIdPathParam,
    PositionIdPathParam,
    UserInfoIdPathParam
} from '../pathsTypes';
import { buildPath, PathComponents as CommonPathComponents } from './commonPaths';
import { EventsPaths } from './eventsPaths';

const PathComponents = {
    ITEMS: {
        POSITIONS: {
            POSITION: 'position',
            POSITIONS: 'positions',
            POSITION_CATEGORY: 'position-category',
            POSITION_CATEGORIES: 'position-categories'
        }
    },
    ACTIONS: {
        IMPORT_CSV: `${CommonPathComponents.ACTIONS.IMPORT}/csv`
    }
};

const ItemPaths = {
    POSITIONS: PathComponents.ITEMS.POSITIONS.POSITIONS,
    POSITION: (positionId: PositionIdPathParam) =>
        buildPath([PathComponents.ITEMS.POSITIONS.POSITION, positionId])
};

export type PositionParams = {
    organizationId: OrganizationIdPathParam;
    eventId: EventIdPathParam;
    positionId: PositionIdPathParam;
};

export type PositionUserParams = PositionParams & {
    userInfoId: UserInfoIdPathParam;
};

export const PositionsPaths = {
    // Access Paths
    POSITION: ({ organizationId, eventId, positionId }: PositionParams) =>
        buildPath([EventsPaths.EVENT(organizationId, eventId), ItemPaths.POSITION(positionId)]),
    POSITION_DASHBOARD: (params: PositionParams) =>
        buildPath([PositionsPaths.POSITION(params), CommonPathComponents.SECTIONS.DASHBOARD]),
    POSITION_MEMBERS: (params: PositionParams) =>
        buildPath([PositionsPaths.POSITION(params), CommonPathComponents.SECTIONS.MEMBERS]),
    POSITION_MEMBER: ({ userInfoId, ...params }: PositionUserParams) =>
        buildPath([
            PositionsPaths.POSITION_MEMBERS(params),
            CommonPathComponents.SECTIONS.MEMBER,
            userInfoId
        ]),
    POSITION_MEMBER_INFORMATION: (params: PositionUserParams) =>
        buildPath([
            PositionsPaths.POSITION_MEMBER(params),
            CommonPathComponents.SECTIONS.INFORMATION
        ]),
    POSITION_IMPORT_CSV: (organizationId: OrganizationIdPathParam, eventId: EventIdPathParam) =>
        buildPath([EventsPaths.EVENT(organizationId, eventId), PathComponents.ACTIONS.IMPORT_CSV])
};
