import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AssignmentInfosQuery,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    SegmentId,
    TagId,
    UsersInfo,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { useAssignmentSlotsQuery } from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';
import { AssignmentCategories } from './assignmentCategories';
import { useAssignmentContext } from './assignmentContext';
import { AssignmentSlots } from './assignmentSlots';
import { AssignmentFilters } from './filters/assignmentFilters';

interface IAssignmentCategoriesSlotsProps {
    event: Pick<
        AssignmentInfosQuery['event'],
        'startAt' | 'endAt' | 'positionsCategories' | 'positions' | 'segments'
    >;
    organization: Pick<AssignmentInfosQuery['organization'], 'tags'>;
    state: VolunteerRegistrationState;
    userInfo: Pick<UsersInfo, 'name' | 'fields'>;
    wishedPositionsCategoriesIds: PositionsCategoryId[];
    wishedPositionsIds: PositionId[];
    wishedPositionsSlotsIds: PositionsSlotId[];
    wishedRanges: Interval[];
}

export const AssignmentCategoriesSlots = (props: IAssignmentCategoriesSlotsProps) => {
    const { eventId } = useParams();
    const { isPreAssign } = useAssignmentContext();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [positionsCategoriesIds, setPositionsCategoriesIds] = useLocalEventState<
        PositionsCategoryId[] | null
    >('assignment.filters.positionsCategoriesIds', null);
    const [positionsIds, setPositionsIds] = useLocalEventState<PositionId[] | null>(
        'assignment.filters.positionsIds',
        null
    );
    const [date, setDate] = useLocalEventState<DateTime | null>('assignment.filters.date', null);
    const [tagsIds, setTagsIds] = useLocalEventState<TagId[] | null>(
        'assignment.filters.tagsIds',
        null
    );
    const [segmentId, setSegmentId] = useLocalEventState<SegmentId | null>(
        'assignment.filters.segmentId',
        null
    );
    const [excludeFull, setExcludeFull] = useLocalEventState(
        'assignment.filters.excludeFull',
        false
    );
    const { data, loader } = useAssignmentSlotsQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        positionsCategoriesIds: isNonEmptyArray(positionsCategoriesIds)
            ? positionsCategoriesIds
            : undefined,
        positionsIds: isNonEmptyArray(positionsIds) ? positionsIds : undefined,
        date,
        tagsIds: isNonEmptyArray(tagsIds) ? tagsIds : undefined,
        segmentId,
        excludeFull
    });

    return (
        <Flex
            css={{
                background: '$gray50',
                minHeight: '100%',
                padding: '$6 $7'
            }}
            direction="column"
            width={1}
        >
            <AssignmentFilters
                date={date}
                event={props.event}
                excludeFull={excludeFull}
                name={name}
                organization={props.organization}
                positionsCategoriesIds={positionsCategoriesIds}
                positionsIds={positionsIds}
                segmentId={segmentId}
                setDate={setDate}
                setExcludeFull={setExcludeFull}
                setName={setName}
                setPositionsCategoriesIds={setPositionsCategoriesIds}
                setPositionsIds={setPositionsIds}
                setSegmentId={setSegmentId}
                setTagsIds={setTagsIds}
                tagsIds={tagsIds}
            />

            <Spacer height="7" />

            {loader ? (
                loader
            ) : isPreAssign ? (
                <AssignmentCategories
                    positionsSlots={data.event?.positionsSlots.nodes ?? []}
                    userInfo={props.userInfo}
                    wishedPositionsCategoriesIds={props.wishedPositionsCategoriesIds}
                    wishedPositionsIds={props.wishedPositionsIds}
                    wishedPositionsSlotsIds={props.wishedPositionsSlotsIds}
                    wishedRanges={props.wishedRanges}
                />
            ) : (
                <AssignmentSlots
                    positionsSlots={data.event?.positionsSlots.nodes ?? []}
                    state={props.state}
                    userInfo={props.userInfo}
                    wishedPositionsCategoriesIds={props.wishedPositionsCategoriesIds}
                    wishedPositionsIds={props.wishedPositionsIds}
                    wishedPositionsSlotsIds={props.wishedPositionsSlotsIds}
                    wishedRanges={props.wishedRanges}
                />
            )}
        </Flex>
    );
};
