import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsNumberOfAccessProps {
    accreditation: Pick<Accreditation, 'id' | 'weezeventNumberOfAccess'>;

    reload(): void;
}

export const AccreditationsNumberOfAccess = (props: IAccreditationsNumberOfAccessProps) => {
    const { translate } = useHeavent();
    const numberService = useService(NumberService);

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.weezeventNumberOfAccess as any}
            property={AccreditationProperty.WeezeventNumberOfAccess}
            reload={props.reload}
            renderInput={(value: Emptyable<number>, setValue) => {
                const isLimited = typeof value === 'number';

                return (
                    <Flex align="end" gap="4">
                        <TextInput
                            disabled={!isLimited}
                            label={translate('WeezeventNumberOfAccess')}
                            shouldParseAsInt={true}
                            value={value || ''}
                            onChange={setValue}
                        />

                        <Flex align="center" height={40}>
                            <CheckboxText
                                state={isLimited ? 'unchecked' : 'checked'}
                                onClick={(newValue) => {
                                    if (newValue === 'checked') {
                                        setValue(null);
                                    } else {
                                        setValue(1);
                                    }
                                }}
                            >
                                {translate('illimite')}
                            </CheckboxText>
                        </Flex>
                    </Flex>
                );
            }}
            renderValue={numberService.toNumberOrInfinity(
                props.accreditation.weezeventNumberOfAccess
            )}
        />
    );
};
