import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsMaxResourcesCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsMaxResourcesCell = (props: IDelegationsMaxResourcesCellProps) => {
    const translate = useTranslate();
    const numberService = useService(NumberService);

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={(props.delegation.maxResources || '') as any}
            property={DelegationProperty.MaxResources}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('MaxResources')}
                    shouldParseAsInt={true}
                    value={value}
                    onChange={setValue}
                />
            )}
            renderValue={numberService.toNumberOrInfinity(props.delegation.maxResources)}
        />
    );
};
