import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Position, PositionProperty } from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsColorCellProps {
    position: Pick<Position, 'id' | 'color'>;

    reload(): void;
}

export const PositionsColorCell = (props: IPositionsColorCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            initialValue={props.position.color}
            positionId={props.position.id}
            property={PositionProperty.Color}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <ColorInput label={translate('Color')} value={value} onChange={setValue} />
            )}
            renderValue={() => (
                <Flex align="center" gap="3" width={1}>
                    <Box
                        css={{
                            background: props.position.color,
                            borderRadius: '2px',
                            height: '24px',
                            width: '24px'
                        }}
                    />

                    <Box>{props.position.color}</Box>
                </Flex>
            )}
        />
    );
};
