import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersGeneralCustomFields } from '../../forms/update/parameters/general/formParametersGeneralCustomFields';

export const PresetsFormBox = (
    props: Omit<React.ComponentProps<typeof FormParametersGeneralCustomFields>, 'name'>
) => {
    const translate = useTranslate();

    return (
        <FormBox
            initialIsOpen={false}
            subtitle={translate('d_finissez_dire_31742')}
            title={translate('attribution_d_u_50979')}
        >
            <FormParametersGeneralCustomFields name="input.presets" {...props} />
        </FormBox>
    );
};
