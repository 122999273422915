import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useVolunteersRegistrationsMassDeleteMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Feature } from 'common/src/generated/types';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useVolunteersRegistrationsMassWeezeventSynchronizeMutation } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsGeneralProps {
    isWeezeventConnected: boolean;
    nameOrEmail: string;
}

export const EventUserProfileActionsGeneral = (props: IEventUserProfileActionsGeneralProps) => {
    const {
        params: { eventId, userInfoId },
        translate
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();
    const {
        mutate: volunteersRegistrationsMassWeezeventSynchronize,
        isLoading: isVolunteersRegistrationsMassWeezeventSynchronizeLoading
    } = useVolunteersRegistrationsMassWeezeventSynchronizeMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteersRegistrationsMassDeleteMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const showWeezevent =
        hasFeature(Feature.Weezevent) && props.isWeezeventConnected && isEventAdmin;

    return (
        <>
            <UserProfileActionsContainer title={translate('g_n_ral_64953')}>
                {showWeezevent && (
                    <UserProfileAction
                        color="error"
                        icon="arrows-rotate"
                        isLoading={isVolunteersRegistrationsMassWeezeventSynchronizeLoading}
                        onClick={async () => {
                            await volunteersRegistrationsMassWeezeventSynchronize({
                                eventId,
                                selecteds: { ids: [userInfoId] }
                            });
                        }}
                    >
                        {translate('synchroniser_av_82451')}
                    </UserProfileAction>
                )}

                <UserProfileAction
                    color="error"
                    icon="user-slash"
                    onClick={() => {
                        setIsDeleteModalOpen(true);
                    }}
                >
                    {translate('d_sinscrire_de_06416')}
                </UserProfileAction>
            </UserProfileActionsContainer>

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('d_sinscrire_27046')}
                    subtext={[
                        translate('_tes_vous_s_r_d_29576'),
                        translate('_1_sera_toujou_13334', props.nameOrEmail)
                    ]}
                    text={translate('d_sinscription_69522', props.nameOrEmail)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            selecteds: { ids: [userInfoId] }
                        });

                        // TODO props.onDelete();
                    }}
                />
            )}
        </>
    );
};
