import { UpdateCell } from 'common-front/src/components/cells/updateCell';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationUpdateFieldMutation } from '../../../generated/graphqlHooks';

interface IDelegationsUpdateCellProps<T> {
    delegation: DelegationsDelegationFragment;
    initialValue: T;
    property: DelegationProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
    renderValue: string | (() => React.ReactNode);
}

export const DelegationsUpdateCell = <T extends {}>(props: IDelegationsUpdateCellProps<T>) => {
    const { eventId, organizationId } = useSegmentsContext();
    const { mutate } = useDelegationUpdateFieldMutation();

    return (
        <UpdateCell
            initialValue={props.initialValue}
            mutate={async (value) =>
                mutate({
                    organizationId,
                    eventId,
                    delegationId: props.delegation.id,
                    slug: props.property,
                    value
                })
            }
            reload={props.reload}
            renderInput={props.renderInput}
            renderValue={props.renderValue}
        />
    );
};
