import { BarChart } from 'common-front/src/charts/barChart';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { theme } from 'common/src/designSystem/components/stitches';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName, sortedSlots } from 'common/src/vo/accreditationSlot';
import * as React from 'react';
import { useAccreditationChartQuery } from '../../../../generated/graphqlHooks';

export const AccreditationChartCard = () => {
    const {
        translate,
        params: { eventId, accreditationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { data } = useAccreditationChartQuery({ eventId, accreditationId });
    const slots = React.useMemo(
        () => sortedSlots(data.event?.accreditation?.slots ?? []),
        [data.event]
    );
    const labels = React.useMemo(() => {
        if (data.event?.accreditation) {
            const accreditation = data.event.accreditation;

            if (accreditation.hasSlots) {
                return slots.map((slot) =>
                    fullName(dateTimeService, slot, '', { includeAccreditationName: false })
                );
            } else {
                return [accreditation.name];
            }
        } else {
            return [];
        }
    }, [data.event, slots]);
    const datasets = React.useMemo(() => {
        if (data.event?.accreditation) {
            const accreditation = data.event.accreditation;
            const isWeezeventSynchronized = typeof data.event.weezevent?.id === 'number';

            if (accreditation.hasSlots) {
                const hasMax = slots.some((s) => typeof s.maxResources === 'number');
                const datasets = [
                    {
                        label: translate('souhait_es_78101'),
                        data: slots.map((s) => s.wishedResources),
                        backgroundColor: theme.colors.primary400.value
                    },
                    {
                        label: translate('affect_es_00048'),
                        data: slots.map((s) => s.assignedResources),
                        backgroundColor: theme.colors.primary700.value
                    }
                ];

                if (isWeezeventSynchronized) {
                    datasets.push({
                        label: translate('scann_es_13169'),
                        data: slots.map((s) => s.scannedResources),
                        backgroundColor: theme.colors.primary900.value
                    });
                }

                if (hasMax) {
                    datasets.push({
                        label: translate('maximum_56225'),
                        data: slots.map((s) => s.maxResources ?? 0),
                        backgroundColor: theme.colors.gray200.value
                    });
                }

                return datasets;
            } else {
                const datasets = [
                    {
                        label: translate('souhait_es_78101'),
                        data: [accreditation.hiddenSlot.wishedResources],
                        backgroundColor: theme.colors.primary400.value
                    },
                    {
                        label: translate('affect_es_00048'),
                        data: [accreditation.hiddenSlot.assignedResources],
                        backgroundColor: theme.colors.primary700.value
                    }
                ];

                if (isWeezeventSynchronized) {
                    datasets.push({
                        label: translate('scann_es_13169'),
                        data: [accreditation.hiddenSlot.scannedResources],
                        backgroundColor: theme.colors.primary900.value
                    });
                }

                return datasets;
            }
        } else {
            return [];
        }
    }, [data.event, slots]);

    return (
        <DetailBox title="Reporting">
            <Box height={380} width={1}>
                <BarChart datasets={datasets} labels={labels} />
            </Box>
        </DetailBox>
    );
};
