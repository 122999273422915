import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationState,
    EmailType,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useEmailResendMutation,
    useVolunteersRegistrationsMassAccreditationStateUpdateMutation
} from '../../../generated/graphqlHooks';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsAccreditationProps {
    accreditationState: AccreditationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const EventUserProfileActionsAccreditation = (
    props: IEventUserProfileActionsAccreditationProps
) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const { mutate: emailResend, isLoading: isEmailResendLoading } = useEmailResendMutation();
    const {
        mutate: accreditationStateNotApplicable,
        isLoading: isAccreditationStateNotApplicableLoading
    } = useVolunteersRegistrationsMassAccreditationStateUpdateMutation();
    const {
        mutate: accreditationStateWaitingAccreditation,
        isLoading: isAccreditationStateWaitingAccreditationLoading
    } = useVolunteersRegistrationsMassAccreditationStateUpdateMutation();
    const showChangeToWaiting =
        props.accreditationState !== AccreditationState.WaitingAccreditation;
    const showChangeToRefused = props.accreditationState !== AccreditationState.Refused;
    const showChangeToNotApplicable =
        props.accreditationState === AccreditationState.WaitingAccreditation ||
        props.accreditationState === AccreditationState.Accredited;
    const showEditAccreditation = props.accreditationState === AccreditationState.Accredited;
    const showAccredit = props.accreditationState === AccreditationState.WaitingAccreditation;
    const showResendAccreditationEmail = props.accreditationState === AccreditationState.Accredited;

    return (
        <UserProfileActionsContainer title={translate('Accreditation')}>
            {showChangeToWaiting && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateWaitingAccreditationLoading}
                    onClick={async () => {
                        await accreditationStateWaitingAccreditation({
                            eventId,
                            massStateUpdate: {
                                selecteds: { ids: [userInfoId] },
                                state: AccreditationState.WaitingAccreditation
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_27197')}
                </UserProfileAction>
            )}

            {showChangeToRefused && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateWaitingAccreditationLoading}
                    onClick={async () => {
                        await accreditationStateWaitingAccreditation({
                            eventId,
                            massStateUpdate: {
                                selecteds: { ids: [userInfoId] },
                                state: AccreditationState.Refused
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_62868')}
                </UserProfileAction>
            )}

            {showChangeToNotApplicable && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateNotApplicableLoading}
                    onClick={async () => {
                        await accreditationStateNotApplicable({
                            eventId,
                            massStateUpdate: {
                                selecteds: { ids: [userInfoId] },
                                state: AccreditationState.NotApplicable
                            }
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_92651')}
                </UserProfileAction>
            )}

            {showEditAccreditation && (
                <UserProfileAction
                    color="success"
                    icon="pen"
                    path={HeaventPaths.ACCREDIT(organizationId, eventId, userInfoId)}
                >
                    {translate('_diter_les_accr_86042')}
                </UserProfileAction>
            )}

            {showAccredit && (
                <UserProfileAction
                    color="success"
                    icon="badge-check"
                    path={HeaventPaths.ACCREDIT(organizationId, eventId, userInfoId)}
                >
                    {translate('accr_diter_70543')}
                </UserProfileAction>
            )}

            {showResendAccreditationEmail && (
                <UserProfileAction
                    color="success"
                    icon="paper-plane"
                    isLoading={isEmailResendLoading}
                    onClick={async () => {
                        await emailResend({
                            eventId,
                            userInfoId,
                            emailType: EmailType.Accreditations
                        });
                    }}
                >
                    {translate('r_envoyer_l_e_24677')}
                </UserProfileAction>
            )}
        </UserProfileActionsContainer>
    );
};
